<template>
  <div>

    <v-row>
      <v-col cols="12" md="6" lg="6">
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn v-bind="attrs" v-on="on" class="my-6" @click="tryCreateNewAccount" color="primary" small>
              <v-icon class="mr-1">mdi-plus</v-icon>
              New Account
            </v-btn>
          </template>
          <span>Create new account</span>
        </v-tooltip>

      </v-col>
      <v-col cols="12" md="6" lg="6">
<!--        <v-select-->
<!--            :items="distinct_years"-->
<!--            label="Year"-->
<!--        >-->
<!--        </v-select>-->
      </v-col>
    </v-row>
    <!-- Search field -->
    <v-divider></v-divider>
    <v-text-field
        dense
        filled
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
    ></v-text-field>
    <v-spacer></v-spacer>
    <!-- end search -->

    <v-data-table
        :headers="headers"
        :items="bank_accounts"
        :search="search"
        item-key="_id"
        sort-by="name"
        class="elevation-22"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title primary >Accounts for {{bank.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-switch
              v-model="singleExpand"
              label="Single expand"
              class="mt-2 white--text"
          ></v-switch>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card>
            <v-card-text class="fourth subtitle-1">
              <v-row>
                <v-col>
                  Record_id: {{item._id}}<br/>
                  {{ item.account_name}}<br/>
                  {{item.description}}<br/>
                  Account #{{item.account_number}}<br/>
                  Balance: {{formatMoney(item.balance) }}<br/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </td>
      </template>
      <template #item.account_title="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{attrs, on}">
            <v-btn color="primary" @click="openAccountInDialog(item)" text>
              <v-icon class="mr-2">mdi-hand-extended</v-icon>
              {{ item.account_title }}
            </v-btn>
          </template>
          <span>{{ bank.name }} - {{ item.account_title }}</span>
        </v-tooltip>

      </template>
      <template #item.account_ytd_balance="{item}">
        {{ formatMoney(item.account_ytd_balance) }}
      </template>
      <template #item.statement_count="{item}">
        {{ item.statement_count ?? 0 }}
      </template>
      <template #item.actions="{item}">
        <v-btn icon @click="findRecord(item)">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="deleteItem(item._id)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>

        <v-tooltip bottom v-if="item.count > 0">
          <template v-slot:activator="{attrs, on}">
            <v-icon class="mx-1" v-bind="attrs" v-on="on" small>mdi-lock</v-icon>
          </template>
          <span>Not available for deletion ({{ item.count }} accounts exists).</span>
        </v-tooltip>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{on, attrs}">
            <v-icon class="mx-1" v-bind="attrs" v-on="on" small>mdi-lock-open-variant</v-icon>
          </template>
          <span>Available for deletion</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn :disabled="!item.notification_showStatus" icon @click="openMessageDialog(item)">
              <v-icon color="red" class="mx-1" v-bind="attrs" v-on="on" small>mdi-bell-ring</v-icon>
            </v-btn>
          </template>
          <span>{{ item.notification_text }}</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="newAccountDialog" max-width="650" persistent>
      <v-card>
        <v-card-title class="primary white--text">
          Add/Edit Account
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                  @change="selectDescription"
                  v-model="account.account_title"
                  :items="account_types_categories"
                  item-text="type"
                  label="Type"
                  :hint="account.account_types_description"
              ></v-select>
              <v-text-field
                  v-model="account.account_number"
                  label="Account number"
                  hint="e.g. 123456789"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                  maxlength="4"
                  :items="calendarYears"
                  v-model="account.accounting_year"
                  label="Accounting year"
                  hint="e.g. John's savings account"
              ></v-autocomplete>
              <v-text-field
                  v-model="account.account_name"
                  label="Account name"
                  hint="e.g. John's savings account"
              ></v-text-field>

            </v-col>
          </v-row>

          <v-textarea
              name="description (Optional)"
              v-model="account.description"
              label="Account description"
          ></v-textarea>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="saveAccount">Submit</v-btn>
          <v-btn @click="newAccountDialog= false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
        v-model="showAccountDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >

      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="showAccountDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $data.account.account_title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click="showAccountDialog = false"
            >
              <v-icon>mdi-keyboard-backspace</v-icon>
              Back to Accounts
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <account-view :account="account"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {localstorageget} from "@/utils/localstorageget";
import {localstorageset} from "@/utils/dataparse";
import filters from "@/utils/filters";
import AccountView from "@/views/AccountView.vue";

export default {
  name: "AccountsView",
  components: {AccountView},
  props: {
    bank: {},
  },
  data() {
    return {
      expanded: [],
      singleExpand: false,
      account_id: null,
      showAccountDialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      account: [],
      newAccountDialog: false,
      search: '',
      banks: [],
      headers: [
        {text: "Acct #", value: 'account_number'},
        {text: "Account", value: 'account_title', align: 'left'},
        {text: "# of Statements", value: 'statement_count', align: 'center'},
        {text: "", value: 'actions'},
      ],
      accounts: [],
      bank_accounts: [],
      account_type: [{text: 'Checking', value: 'checking'}, {text: 'Savings', value: 'savings'}],
      count: 0,
      account_types_categories: [],
      calendarYears: ["2023", "2022", "2021", "2020", "2019"]
    }
  },
  created() {
    this.accounts = localstorageget('accounts') ?? [];
    this.account_types_categories = localstorageget('account_types_categories') ?? [];
    if (this.banks) {
      const bnkObj = this.banks.filter(e => e._id === this.$route.params._id);
      this.bank_accounts = this.accounts.filter(e => e._bank_id === this.$route.params._id);
      this.count = this.bank_accounts.length;
      this.bank.count = this.count;
      this.updateCount()
    }
  },
  methods: {
    ...filters.filters,
    openAccountInDialog(account) {
      this.showAccountDialog = true;
      this.account = account;
    },
    selectDescription() {
      this.account.description = '';
      const temp = (this.account_types_categories.filter(e => e.type === this.account.account_title));
      this.account.account_types_description = temp[0].description;
      //this.account.description = temp[0].description;
    },
    findRecord(account) {
      this.newAccountDialog = true;
      this.account = account
    },
    updateCount() {
      this.bank.count = this.bank_accounts.length;
      localstorageset(this.banks, 'banks');
    },
    saveAccount() {
      if (this.account._id && this.account._id.length > 0) {
        // update
        this.accounts.forEach(item => {
          if (item._id === this.account._id) {
            for (let key in this.account) {
              item[key] = this.account[key];
            }
          }
        });
        // this.checkAccountProperties(this.account);
        localstorageset(this.accounts, 'accounts');
        this.account = {};
        this.loadAccounts();
      } else {
        // save new
        if (this.account.account_name.length > 0 && this.account.account_title.length > 0) {

          console.log('create new');
          this.account.registered = true;
          this.account._id = this.generateId();
          this.account._bank_id = this.bank._id;

          console.log('Account::: ', JSON.stringify(this.account));
          console.log('Accounts::: ', JSON.stringify(this.accounts));

          // this.checkAccountProperties(this.account);
          if (this.accounts.length > 0) {
            console.log('Adding to accounts:::', JSON.stringify(this.account))
            this.accounts.push(this.account);
          } else {
            console.log('Adding first account:::')
            this.accounts = [this.account];
          }
          localstorageset(this.accounts, 'accounts');
          this.loadAccounts();
        }
      }
      this.newAccountDialog = false;
    },
    loadAccounts() {
      this.accounts = localstorageget('accounts') ?? [];

    },
    tryCreateNewAccount() {
      this.account = {};
      this.newAccountDialog = true;
    },
    openMessageDialog() {
    },
    editItem(id) {
      alert('Edit id' + id)
    },
    deleteItem(id) {
      alert('delete id' + id)
    },
    yearsSelectList() {

    },

  },
  computed:{
    distinct_years(){
      return this.distinctAccountingYears(this.accounts);
    }
  }
}
</script>

<style scoped>

</style>