import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue';
import RegistrationView from "@/views/RegistrationView.vue";
import SignInView from "@/views/SignInView.vue";
import BankView from "@/views/BankView.vue";
import AdminView from "@/views/AdminView.vue";
import AccountsView from "@/views/AccountsView.vue";
import AccountView from "@/views/AccountView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/banking',
    name: 'Banking',
    component: BankView
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView
  },
  {
    path: '/accounts/:_id',
    name: 'AccountsView',
    component: AccountsView
  },
  {
    path: '/account/:_id',
    name: 'AccountView',
    component: AccountView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignInView
  },
  {
    path: '/register',
    name: 'register',
    component: RegistrationView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
