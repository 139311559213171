<template>
  <div>
    <v-card max-width="600px">

      <v-card-actions>
        <v-btn @click="changeComponent('signin')" text><v-icon class="pr-2">mdi-file-sign</v-icon>Sign in</v-btn>
        <v-btn @click="changeComponent('signup')" text><v-icon class="pr-2">mdi-draw</v-icon>Sign up</v-btn>
        <v-btn @click="changeComponent('support')" text><v-icon class="pr-2">mdi-lock-question</v-icon>I forgot my password</v-btn>
      </v-card-actions>
      <v-divider></v-divider>


      <v-card-text v-if="signin">
        <div class="title py-8">
          Sign In
        </div>
        <v-text-field v-model="username" label="Email"></v-text-field>
        <v-text-field v-model="password" label="Password" type="password"></v-text-field>
        <v-btn>Login</v-btn>
      </v-card-text>

      <v-card-text v-if="signup">
        <div class="title py-8">
          Sign up for an account
        </div>
        <v-text-field v-model="username" label="Email"></v-text-field>
        <v-text-field v-model="password" label="Password" type="password"></v-text-field>
        <v-text-field v-model="verifyPassword" label="Password Retype" type="password"></v-text-field>
        <v-btn>Register</v-btn>
      </v-card-text>

      <v-card-text v-if="support">
        <div class="title py-8">
          I forgot my password
        </div>
        <div>
          Enter your email and we will send you instructions
        </div>
        <v-text-field v-model="username" label="Email"></v-text-field>
        <v-btn>Send</v-btn>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "LoginForm",
  data(){
    return {
      username: '',
      password: '',
      verifyPassword: '',
      signup: false,
      signin: true,
      support: false,
    }
  },
  methods:{
    changeComponent(val){
      this.signup = val === 'signup';
      this.signin = val === 'signin';
      this.support = val === 'support';
    }
  },
}
</script>

<style scoped>

</style>