import { render, staticRenderFns } from "./LoginForm.vue?vue&type=template&id=bc4b10b8&scoped=true&"
import script from "./LoginForm.vue?vue&type=script&lang=js&"
export * from "./LoginForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc4b10b8",
  null
  
)

export default component.exports