/**
 * Save data to localStorage
 * @param data
 * @param table
 * @param stringifyIt
 */
function localstorageset(data, table, stringifyIt = true) {
  if (stringifyIt) {
    return localStorage.setItem(table, JSON.stringify(data))
  } else {
    return localStorage.setItem(table, data)
  }
}

module.exports = localstorageset;
