<template>
  <v-row>
    <v-col cols="12" md="12" lg="12">
      <v-spacer></v-spacer>
      <v-text-field
          dense
          filled
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-data-table
          :sort-by="['name']"
          :headers="headers"
          :items="banks"
          :search="search"
          item-key="name"
          :items-per-page="15"
          class="elevation-22"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          show-expand
      >

        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title primary >Financial Institutions</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-switch
                v-model="singleExpand"
                label="Single expand"
                class="mt-2 white--text"
            ></v-switch>
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-card>
              <v-card-text class="fourth subtitle-1">
                <v-row>
                  <v-col>
                    {{ item.type }}<br/>
                    {{ item.name }} ({{ item.acronym }})<br/>
                    Routing #{{item.routing}}<br/>
                    {{item.address}}<br/>
                    {{ item.city }} {{ item.state }}  {{ item.postcode }}
                    {{ item.phone }}
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </td>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{attrs, on}">
              <v-btn @click="openBankInDialog(item)" v-bind="attrs" v-on="on" text color="primary">{{
                  item.name
                }}
              </v-btn>
            </template>
            <span>Open {{ item.name }}</span>
          </v-tooltip>

        </template>
        <template v-slot:item.actions="{ item }">
<!--          <v-btn icon @click="findBankRecord(item)">-->
<!--            <v-icon small>mdi-pencil</v-icon>-->
<!--          </v-btn>-->
<!--          <v-btn :disabled="item.count > 0" icon @click="tryDeleteBankById(item)">-->
<!--            <v-icon small>mdi-delete</v-icon>-->
<!--          </v-btn>-->

<!--          <v-tooltip bottom v-if="item.count > 0">-->
<!--            <template v-slot:activator="{attrs, on}">-->
<!--              <v-icon class="mx-1" v-bind="attrs" v-on="on" small>mdi-lock</v-icon>-->
<!--            </template>-->
<!--            <span>Not available for deletion ({{ item.count }} accounts exists).</span>-->
<!--          </v-tooltip>-->
<!--          <v-tooltip bottom v-else>-->
<!--            <template v-slot:activator="{on, attrs}">-->
<!--              <v-icon class="mx-1" v-bind="attrs" v-on="on" small>mdi-lock-open-variant</v-icon>-->
<!--            </template>-->
<!--            <span>Available for deletion</span>-->
<!--          </v-tooltip>-->
<!--          <v-tooltip bottom>-->
<!--            <template v-slot:activator="{on, attrs}">-->
<!--              <v-btn :disabled="!item.notification_showStatus" icon @click="openMessageDialog(item)">-->
<!--                <v-icon color="red" class="mx-1" v-bind="attrs" v-on="on" small>mdi-bell-ring</v-icon>-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <span>{{ item.notification_text }}</span>-->
<!--          </v-tooltip>-->
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TableBanks",
  props:{
    banks: {
      type: Array,
      default: []
    }
  },
  data(){
    return {
      singleExpand: false,
      expanded: [],
      search: '',
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'name',
        }
      ],
    }
  },
  methods: {
    openBankInDialog(bank) {
      this.$emit('openBankDialog', bank)
    },
  }
}
</script>

<style scoped>

</style>