<template>
  <v-app id="BudgetSmart">
    <v-navigation-drawer
        class="d-print-none"
        v-model="drawer"
        app
    >
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="deep-green--text text--accent-4">
          <v-list-item :disabled="item.disabled" v-for="item in menuItems" :key="item.title" @click="clickItem(item)">
            <v-list-item-action>
              <v-icon>mdi-{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
        app
        color="primary"
        dark
        class="d-print-none"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="hidden-print-only">BudgetSmart</v-toolbar-title>
      <v-spacer/>
      {{ application.version }}
    </v-app-bar>
    <v-main class="ma-4">
      <router-view/>
    </v-main>
    <v-footer app inset class="hidden-print-only">
      <span class="white--text">
       All rights reserved none waved | &copy;2018 Billaking.com
      </span>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    group: null,
    row: null,
    application: {
      "name": "BudgetSmart",
      "version": "0.0.1",
      "release": "2022-05-31",
      "support_website": "support@billaking.com",
      "email": "billaking@gmail.com",
      "support_email": "support@billaking.com"
    },
    drawer: false,
    menuItems: [
      {
        disabled: false,
        icon: 'view-dashboard',
        title: 'Home',
        href: '/'
      },
      {
        disabled: false,
        icon: 'home',
        title: 'Banking',
        href: '/banking',
      },
      {
        disabled: true,
        icon: 'menu',
        title: 'Shopping',
        href: '/shopping'
      },
      {
        disabled: true,
        icon: 'scale-balance',
        title: 'Lifestyle',
        href: '/lifestyle',
        description: 'health, fitness, mental, spiritual, balance'
      },
      {
        disabled: true,
        icon: 'cash-multiple',
        title: 'Contributions',
        href: '/contributions-monetary',
      },
      {
        disabled: true,
        icon: 'cash-remove',
        title: 'Expenditures',
        href: '/expenditures-monetary',
      },
      {
        disabled: true,
        icon: 'account-plus',
        title: 'Registration',
        href: '/registration'
      },
      {
        disabled: true,
        icon: 'information',
        title: 'About',
        href: '/about'
      },
      {
        disabled: true,
        icon: 'face-agent',
        title: 'Help & Support',
        href: '/help'
      },
      {
        disabled: false,
        icon: 'cog',
        title: 'Administration',
        href: '/admin'
      },
    ]
  }),
  methods: {
    clickItem(item) {
      this.$router.push(item.href)
    },
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
};
</script>
<style lang="scss">
@import 'main.scss';

footer {
  padding: 1em;
}


</style>
