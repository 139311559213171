import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ButtonNewInstitution'),_c('TableBanks',{attrs:{"banks":_vm.banks},on:{"openBankDialog":function($event){return _vm.openBankInDialog(_vm.bank)}}}),_c(VDialog,{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showBankAccountsDialog),callback:function ($$v) {_vm.showBankAccountsDialog=$$v},expression:"showBankAccountsDialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showBankAccountsDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.bank.name)+" ")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({attrs, on}){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("View or edit "+_vm._s(_vm.bank.name)+"'s contact information")])]),_c(VSpacer),_c(VToolbarItems,[_c(VBtn,{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.showBankAccountsDialog = false}}},[_c(VIcon,[_vm._v("mdi-keyboard-backspace")]),_vm._v(" Back to Banks ")],1)],1)],1),_c(VCardText,[_c('accounts-view',{attrs:{"bank":_vm.bank}})],1)],1)],1),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.showErrorDialog),callback:function ($$v) {_vm.showErrorDialog=$$v},expression:"showErrorDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"darken-2 green white--text"},[_vm._v("Error")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.showError.message)+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }