import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"BudgetSmart"}},[_c(VNavigationDrawer,{staticClass:"d-print-none",attrs:{"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItemGroup,{attrs:{"active-class":"deep-green--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},_vm._l((_vm.menuItems),function(item){return _c(VListItem,{key:item.title,attrs:{"disabled":item.disabled},on:{"click":function($event){return _vm.clickItem(item)}}},[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-"+_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1),_c(VAppBar,{staticClass:"d-print-none",attrs:{"app":"","color":"primary","dark":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c(VToolbarTitle,{staticClass:"hidden-print-only"},[_vm._v("BudgetSmart")]),_c(VSpacer),_vm._v(" "+_vm._s(_vm.application.version)+" ")],1),_c(VMain,{staticClass:"ma-4"},[_c('router-view')],1),_c(VFooter,{staticClass:"hidden-print-only",attrs:{"app":"","inset":""}},[_c('span',{staticClass:"white--text"},[_vm._v(" All rights reserved none waved | ©2018 Billaking.com ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }