<template>
<div><h1>Registration View</h1></div>
</template>

<script>
export default {
  name: "RegistrationView"
}
</script>

<style scoped>

</style>