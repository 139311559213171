<template>
  <div>
    <v-btn @click="resetLocalStorage">Reset local storage</v-btn>
    <v-btn @click="saveBanksToLocalStorage">Create dummy banks</v-btn>
    <v-btn @click="saveUsStatesToLocalStorage">Create dummy Us States</v-btn>
    <v-btn @click="saveAccountsToLocalStorage">Create dummy accounts</v-btn>
    <v-btn @click="saveAccountTitlesToLocalStorage">Create dummy account titles</v-btn>
    <v-btn @click="saveTransactionsToLocalStorage">Create dummy transactions</v-btn>
    <v-btn @click="false">Sync</v-btn>
    <v-simple-table class="ma-4">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Database
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in localStorageTables"
            :key="item"
        >
          <td>{{ item }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import {localstorageremove} from "@/utils/localstorageremove";
import localstorageset from "../utils/localstorageset";
import {listTablesInLocalStorage} from "@/utils/localstorageget";
import filters from "@/utils/filters";

export default {
  name: "AdminView",
  data() {
    return {
      localStorageTables: [],
      localTables: 'No Tables found',
      account_titles: [],
      accounts: [
        {
          "_id": 50,
          "account_title": "Savings Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Savings Account",
          "description": "Savings account for John Doe",
          "account_number": "123456789",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 51,
          "account_title": "Checking Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Checking Account",
          "description": "Checking account for John Doe",
          "account_number": "987654321",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 52,
          "account_title": "Investment Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Investment Account",
          "description": "Investment account for John Doe",
          "account_number": "123456789",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 53,
          "account_title": "Retirement Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Retirement Account",
          "description": "Retirement account for John Doe",
          "account_number": "987654321",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 54,
          "account_title": "Credit Card Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Credit Card Account",
          "description": "Credit card account for John Doe",
          "account_number": "123456789",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 55,
          "account_title": "Debit Card Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Debit Card Account",
          "description": "Debit card account for John Doe",
          "account_number": "987654321",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 56,
          "account_title": "Loan Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Loan Account",
          "description": "Loan account for John Doe",
          "account_number": "123456789",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 57,
          "account_title": "Mortgage Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Mortgage Account",
          "description": "Mortgage account for John Doe",
          "account_number": "987654321",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 58,
          "account_title": "Line of Credit Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Line of Credit Account",
          "description": "Line of credit account for John Doe",
          "account_number": "123456789",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 59,
          "account_title": "Savings Bond Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Savings Bond Account",
          "description": "Savings bond account for John Doe",
          "account_number": "987654321",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": "1",
          "account_title": "Savings Account",
          "_bank_id": "4",
          "account_name": "My Savings Account",
          "description": "This is my savings account for emergency funds.",
          "account_number": "123456",
          "accounting_year": "2017",
          "creation_date": "01-01-2017",
          "account_status": "Not Active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": "2",
          "account_title": "Checking Account",
          "account_name": "My Checking Account",
          "_bank_id": "4",
          "description": "This is my checking account for everyday expenses.",
          "account_number": "789012",
          "accounting_year": "2012",
          "creation_date": "01-01-2012",
          "account_status": "Not Active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": "3",
          "account_title": "Retirement Account",
          "account_name": "My Retirement Account",
          "_bank_id": "4",
          "description": "This is my retirement account for long-term savings.",
          "account_number": "345678",
          "accounting_year": "2020",
          "creation_date": "01-01-2020",
          "account_status": "Active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": "4",
          "account_title": "Investment Account",
          "account_name": "My Investment Account",
          "description": "This is my investment account for stocks and bonds.",
          "account_number": "901234",
          "accounting_year": "2022",
          "creation_date": "01-01-2022",
          "account_status": "Active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": "5",
          "account_title": "Credit Card Account",
          "account_name": "My Credit Card Account",
          "description": "This is my credit card account for short-term expenses.",
          "account_number": "567890",
          "accounting_year": "2020",
          "creation_date": "01-01-2020",
          "account_status": "Active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": "6",
          "account_title": "Loan Account",
          "account_name": "My Loan Account",
          "description": "This is my loan account for large purchases.",
          "account_number": "234567",
          "accounting_year": "2020",
          "creation_date": "01-01-2020",
          "account_status": "Active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": "7",
          "account_title": "CD Account",
          "account_name": "My CD Account",
          "description": "This is my CD account for short-term savings.",
          "account_number": "890123",
          "accounting_year": "2020",
          "creation_date": "01-01-2020",
          "account_status": "Active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": "8",
          "account_title": "Savings Bond Account",
          "account_name": "My Savings Bond Account",
          "description": "This is my savings bond account for long-term savings.",
          "account_number": "678901",
          "accounting_year": "2020",
          "creation_date": "01-01-2020",
          "account_status": "Active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": "9",
          "account_title": "Money Market Account",
          "account_name": "My Money Market Account",
          "description": "This is my money market account for higher interest rates.",
          "account_number": "345678",
          "accounting_year": "2020",
          "creation_date": "01-01-2020",
          "account_status": "Active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": "10",
          "account_title": "Gift Card Account",
          "account_name": "My Gift Card Account",
          "description": "This is my gift card account for gifts and rewards.",
          "account_number": "901234",
          "accounting_year": "2020",
          "creation_date": "01-01-2020",
          "account_status": "Active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        }, {
          "_id": 10,
          "account_title": "Savings Account",
          "_bank_id": "123456",
          "account_name": "My Savings",
          "description": "This is my personal savings account",
          "account_number": "123456789",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 11,
          "account_title": "Checking Account",
          "_bank_id": "123456",
          "account_name": "My Checking",
          "description": "This is my personal checking account",
          "account_number": "987654321",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 12,
          "account_title": "Retirement Account",
          "_bank_id": "123456",
          "account_name": "My Retirement",
          "description": "This is my personal retirement account",
          "account_number": "567891234",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 13,
          "account_title": "Investment Account",
          "_bank_id": "123456",
          "account_name": "My Investments",
          "description": "This is my personal investment account",
          "account_number": "123498765",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 14,
          "account_title": "Credit Card Account",
          "_bank_id": "123456",
          "account_name": "My Credit Card",
          "description": "This is my personal credit card account",
          "account_number": "456789123",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 15,
          "account_title": "Loan Account",
          "_bank_id": "123456",
          "account_name": "My Loan",
          "description": "This is my personal loan account",
          "account_number": "789123456",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 16,
          "account_title": "Saving Bond Account",
          "_bank_id": "123456",
          "account_name": "My Saving Bond",
          "description": "This is my personal saving bond account",
          "account_number": "345678912",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 17,
          "account_title": "Money Market Account",
          "_bank_id": "123456",
          "account_name": "My Money Market",
          "description": "This is my personal money market account",
          "account_number": "891234567",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 18,
          "account_title": "Certificate of Deposit Account",
          "_bank_id": "123456",
          "account_name": "My CD",
          "description": "This is my personal certificate of deposit account",
          "account_number": "678912345",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        },
        {
          "_id": 19,
          "account_title": "Health Savings Account",
          "_bank_id": "123456",
          "account_name": "My HSA",
          "description": "This is my personal health savings account",
          "account_number": "912345678",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active",
          "count": 0,
          "notification_text": "This account is incomplete",
          "notification_showStatus": true,
        }
      ],
      transactions: [
        {
          "_id": 100,
          "transaction_date": "2020-01-01",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 101,
          "transaction_date": "2020-01-02",
          "transaction_type": "credit",
          "transaction_payee": "Paycheck",
          "transaction_category": "Income",
          "transaction_memo": "January Paycheck",
          "transaction_amount": 2000,
          "_account_id": 50
        },
        {
          "_id": 102,
          "transaction_date": "2020-01-03",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 103,
          "transaction_date": "2020-01-04",
          "transaction_type": "debit",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": -30,
          "_account_id": 50
        },
        {
          "_id": 104,
          "transaction_date": "2020-01-05",
          "transaction_type": "debit",
          "transaction_payee": "Utilities",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "January Utilities",
          "transaction_amount": -150,
          "_account_id": 50
        },
        {
          "_id": 105,
          "transaction_date": "2020-01-06",
          "transaction_type": "credit",
          "transaction_payee": "Rent",
          "transaction_category": "Housing",
          "transaction_memo": "January Rent",
          "transaction_amount": 1500,
          "_account_id": 50
        },
        {
          "_id": 106,
          "transaction_date": "2020-01-07",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 107,
          "transaction_date": "2020-01-08",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 108,
          "transaction_date": "2020-01-09",
          "transaction_type": "debit",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": -30,
          "_account_id": 50
        },
        {
          "_id": 109,
          "transaction_date": "2020-01-10",
          "transaction_type": "credit",
          "transaction_payee": "Paycheck",
          "transaction_category": "Income",
          "transaction_memo": "January Paycheck",
          "transaction_amount": 2000,
          "_account_id": 50
        },
        {
          "_id": 110,
          "transaction_date": "2020-01-11",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 111,
          "transaction_date": "2020-01-12",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 112,
          "transaction_date": "2020-01-13",
          "transaction_type": "debit",
          "transaction_payee": "Utilities",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "January Utilities",
          "transaction_amount": -150,
          "_account_id": 50
        },
        {
          "_id": 113,
          "transaction_date": "2020-01-14",
          "transaction_type": "debit",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": -30,
          "_account_id": 50
        },
        {
          "_id": 114,
          "transaction_date": "2020-01-15",
          "transaction_type": "credit",
          "transaction_payee": "Rent",
          "transaction_category": "Housing",
          "transaction_memo": "January Rent",
          "transaction_amount": 1500,
          "_account_id": 50
        },
        {
          "_id": 115,
          "transaction_date": "2020-01-16",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 116,
          "transaction_date": "2020-01-17",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 117,
          "transaction_date": "2020-01-18",
          "transaction_type": "credit",
          "transaction_payee": "Paycheck",
          "transaction_category": "Income",
          "transaction_memo": "January Paycheck",
          "transaction_amount": 2000,
          "_account_id": 50
        },
        {
          "_id": 118,
          "transaction_date": "2020-01-19",
          "transaction_type": "debit",
          "transaction_payee": "Utilities",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "January Utilities",
          "transaction_amount": -150,
          "_account_id": 50
        },
        {
          "_id": 119,
          "transaction_date": "2020-01-20",
          "transaction_type": "debit",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": -30,
          "_account_id": 50
        },
        {
          "_id": 120,
          "transaction_date": "2020-01-21",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 121,
          "transaction_date": "2020-01-22",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 122,
          "transaction_date": "2020-01-23",
          "transaction_type": "credit",
          "transaction_payee": "Rent",
          "transaction_category": "Housing",
          "transaction_memo": "January Rent",
          "transaction_amount": 1500,
          "_account_id": 50
        },
        {
          "_id": 123,
          "transaction_date": "2020-01-24",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 124,
          "transaction_date": "2020-01-25",
          "transaction_type": "debit",
          "transaction_payee": "Utilities",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "January Utilities",
          "transaction_amount": -150,
          "_account_id": 50
        },
        {
          "_id": 125,
          "transaction_date": "2020-01-26",
          "transaction_type": "credit",
          "transaction_payee": "Paycheck",
          "transaction_category": "Income",
          "transaction_memo": "January Paycheck",
          "transaction_amount": 2000,
          "_account_id": 50
        },
        {
          "_id": 126,
          "transaction_date": "2020-01-27",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 127,
          "transaction_date": "2020-01-28",
          "transaction_type": "debit",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": -30,
          "_account_id": 50
        },
        {
          "_id": 128,
          "transaction_date": "2020-01-29",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 129,
          "transaction_date": "2020-01-30",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 130,
          "transaction_date": "2020-01-31",
          "transaction_type": "credit",
          "transaction_payee": "Rent",
          "transaction_category": "Housing",
          "transaction_memo": "January Rent",
          "transaction_amount": 1500,
          "_account_id": 50
        },
        {
          "_id": 131,
          "transaction_date": "2020-02-01",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 132,
          "transaction_date": "2020-02-02",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 133,
          "transaction_date": "2020-02-03",
          "transaction_type": "debit",
          "transaction_payee": "Utilities",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "January Utilities",
          "transaction_amount": -150,
          "_account_id": 50
        },
        {
          "_id": 134,
          "transaction_date": "2020-02-04",
          "transaction_type": "debit",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": -30,
          "_account_id": 50
        },
        {
          "_id": 135,
          "transaction_date": "2020-02-05",
          "transaction_type": "credit",
          "transaction_payee": "Paycheck",
          "transaction_category": "Income",
          "transaction_memo": "January Paycheck",
          "transaction_amount": 2000,
          "_account_id": 50
        },
        {
          "_id": 136,
          "transaction_date": "2020-02-06",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 137,
          "transaction_date": "2020-02-07",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 138,
          "transaction_date": "2020-02-08",
          "transaction_type": "debit",
          "transaction_payee": "Utilities",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "January Utilities",
          "transaction_amount": -150,
          "_account_id": 50
        },
        {
          "_id": 139,
          "transaction_date": "2020-02-09",
          "transaction_type": "debit",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": -30,
          "_account_id": 50
        },
        {
          "_id": 100,
          "_account_id": 50,
          "transaction_date": "2021-02-01",
          "transaction_payee": "McDonalds",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Lunch",
          "transaction_amount": 15.00
        },
        {
          "_id": 101,
          "_account_id": 50,
          "transaction_date": "2021-02-02",
          "transaction_payee": "Walmart",
          "transaction_category": "Shopping",
          "transaction_memo": "Groceries",
          "transaction_amount": 75.00
        },
        {
          "_id": 102,
          "_account_id": 50,
          "transaction_date": "2021-02-03",
          "transaction_payee": "Target",
          "transaction_category": "Shopping",
          "transaction_memo": "Household supplies",
          "transaction_amount": 50.00
        },
        {
          "_id": 103,
          "_account_id": 50,
          "transaction_date": "2021-02-04",
          "transaction_payee": "Apple Store",
          "transaction_category": "Shopping",
          "transaction_memo": "New phone",
          "transaction_amount": 800.00
        },
        {
          "_id": 104,
          "_account_id": 50,
          "transaction_date": "2021-02-05",
          "transaction_payee": "Starbucks",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Coffee",
          "transaction_amount": 5.00
        },
        {
          "_id": 105,
          "_account_id": 50,
          "transaction_date": "2021-02-06",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up tank",
          "transaction_amount": 40.00
        },
        {
          "_id": 106,
          "_account_id": 50,
          "transaction_date": "2021-02-07",
          "transaction_payee": "Utility Company",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "Electricity bill",
          "transaction_amount": 120.00
        },
        {
          "_id": 107,
          "_account_id": 50,
          "transaction_date": "2021-02-08",
          "transaction_payee": "Amazon",
          "transaction_category": "Shopping",
          "transaction_memo": "Books",
          "transaction_amount": 25.00
        },
        {
          "_id": 108,
          "_account_id": 50,
          "transaction_date": "2021-02-09",
          "transaction_payee": "Bank",
          "transaction_category": "Transfer",
          "transaction_memo": "Transfer to savings",
          "transaction_amount": 100.00
        },
        {
          "_id": 109,
          "_account_id": 50,
          "transaction_date": "2021-02-10",
          "transaction_payee": "Pharmacy",
          "transaction_category": "Health & Fitness",
          "transaction_memo": "Prescription",
          "transaction_amount": 30.00
        },
        {
          "_id": 110,
          "_account_id": 50,
          "transaction_date": "2021-02-11",
          "transaction_payee": "Home Depot",
          "transaction_category": "Shopping",
          "transaction_memo": "Tools",
          "transaction_amount": 45.00
        },
        {
          "_id": 111,
          "_account_id": 50,
          "transaction_date": "2021-02-12",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Weekly groceries",
          "transaction_amount": 75.00
        },
        {
          "_id": 112,
          "_account_id": 50,
          "transaction_date": "2021-02-13",
          "transaction_payee": "Movie Theater",
          "transaction_category": "Entertainment",
          "transaction_memo": "Movie night",
          "transaction_amount": 25.00
        },
        {
          "_id": 113,
          "_account_id": 50,
          "transaction_date": "2021-02-14",
          "transaction_payee": "Subway",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Lunch",
          "transaction_amount": 10.00
        },
        {
          "_id": 114,
          "_account_id": 50,
          "transaction_date": "2021-02-15",
          "transaction_payee": "Clothing Store",
          "transaction_category": "Shopping",
          "transaction_memo": "New shirt",
          "transaction_amount": 25.00
        },
        {
          "_id": 115,
          "_account_id": 50,
          "transaction_date": "2021-02-16",
          "transaction_payee": "Insurance Company",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "Car insurance",
          "transaction_amount": 150.00
        },
        {
          "_id": 116,
          "_account_id": 50,
          "transaction_date": "2021-02-17",
          "transaction_payee": "Home Improvement Store",
          "transaction_category": "Shopping",
          "transaction_memo": "Paint",
          "transaction_amount": 50.00
        },
        {
          "_id": 117,
          "_account_id": 50,
          "transaction_date": "2021-02-18",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": 40.00
        },
        {
          "_id": 118,
          "_account_id": 50,
          "transaction_date": "2021-02-19",
          "transaction_payee": "Department Store",
          "transaction_category": "Shopping",
          "transaction_memo": "New shoes",
          "transaction_amount": 75.00
        },
        {
          "_id": 119,
          "_account_id": 50,
          "transaction_date": "2021-02-20",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up tank",
          "transaction_amount": 40.00
        },
        {
          "_id": 120,
          "_account_id": 50,
          "transaction_date": "2021-02-21",
          "transaction_payee": "Gym",
          "transaction_category": "Health & Fitness",
          "transaction_memo": "Monthly membership",
          "transaction_amount": 50.00
        },
        {
          "_id": 121,
          "_account_id": 50,
          "transaction_date": "2021-02-22",
          "transaction_payee": "Taxi",
          "transaction_category": "Transportation",
          "transaction_memo": "Taxi ride",
          "transaction_amount": 20.00
        },
        {
          "_id": 122,
          "_account_id": 50,
          "transaction_date": "2021-02-23",
          "transaction_payee": "Hardware Store",
          "transaction_category": "Shopping",
          "transaction_memo": "Tools",
          "transaction_amount": 25.00
        },
        {
          "_id": 123,
          "_account_id": 50,
          "transaction_date": "2021-02-24",
          "transaction_payee": "Netflix",
          "transaction_category": "Entertainment",
          "transaction_memo": "Monthly subscription",
          "transaction_amount": 10.00
        },
        {
          "_id": 124,
          "_account_id": 50,
          "transaction_date": "2021-02-25",
          "transaction_payee": "Starbucks",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Coffee",
          "transaction_amount": 5.00
        },
        {
          "_id": 125,
          "_account_id": 50,
          "transaction_date": "2021-02-26",
          "transaction_payee": "Fast Food",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": 12.00
        },
        {
          "_id": 126,
          "_account_id": 50,
          "transaction_date": "2021-02-27",
          "transaction_payee": "Furniture Store",
          "transaction_category": "Shopping",
          "transaction_memo": "New couch",
          "transaction_amount": 500.00
        },
        {
          "_id": 127,
          "_account_id": 50,
          "transaction_date": "2021-02-28",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Weekly groceries",
          "transaction_amount": 75.00
        },
        {
          "_id": 128,
          "_account_id": 50,
          "transaction_date": "2021-03-01",
          "transaction_payee": "Insurance Company",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "Health insurance",
          "transaction_amount": 200.00
        },
        {
          "_id": 129,
          "_account_id": 50,
          "transaction_date": "2021-03-02",
          "transaction_payee": "McDonalds",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Lunch",
          "transaction_amount": 15.00
        },
        {
          "_id": 130,
          "_account_id": 50,
          "transaction_date": "2021-03-03",
          "transaction_payee": "Apple Store",
          "transaction_category": "Shopping",
          "transaction_memo": "New laptop",
          "transaction_amount": 1200.00
        },
        {
          "_id": 131,
          "_account_id": 50,
          "transaction_date": "2021-03-04",
          "transaction_payee": "Hair Salon",
          "transaction_category": "Health & Fitness",
          "transaction_memo": "Haircut",
          "transaction_amount": 25.00
        },
        {
          "_id": 132,
          "_account_id": 50,
          "transaction_date": "2021-03-05",
          "transaction_payee": "Walmart",
          "transaction_category": "Shopping",
          "transaction_memo": "Groceries",
          "transaction_amount": 75.00
        },
        {
          "_id": 133,
          "_account_id": 50,
          "transaction_date": "2021-03-06",
          "transaction_payee": "Taxi",
          "transaction_category": "Transportation",
          "transaction_memo": "Taxi ride",
          "transaction_amount": 20.00
        },
        {
          "_id": 134,
          "_account_id": 50,
          "transaction_date": "2021-03-07",
          "transaction_payee": "Home Depot",
          "transaction_category": "Shopping",
          "transaction_memo": "Tools",
          "transaction_amount": 45.00
        },
        {
          "_id": 135,
          "_account_id": 50,
          "transaction_date": "2021-03-08",
          "transaction_payee": "Utility Company",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "Gas bill",
          "transaction_amount": 50.00
        },
        {
          "_id": 136,
          "_account_id": 50,
          "transaction_date": "2021-03-09",
          "transaction_payee": "Amazon",
          "transaction_category": "Shopping",
          "transaction_memo": "Books",
          "transaction_amount": 25.00
        },
        {
          "_id": 137,
          "_account_id": 50,
          "transaction_date": "2021-03-10",
          "transaction_payee": "Bank",
          "transaction_category": "Transfer",
          "transaction_memo": "Transfer to savings",
          "transaction_amount": 100.00
        },
        {
          "_id": 138,
          "_account_id": 50,
          "transaction_date": "2021-03-11",
          "transaction_payee": "Movie Theater",
          "transaction_category": "Entertainment",
          "transaction_memo": "Movie night",
          "transaction_amount": 25.00
        },
        {
          "_id": 139,
          "_account_id": 50,
          "transaction_date": "2021-03-12",
          "transaction_payee": "Clothing Store",
          "transaction_category": "Shopping",
          "transaction_memo": "New shirt",
          "transaction_amount": 25.00
        },
        {
          "_id": 140,
          "_account_id": 50,
          "transaction_date": "2021-03-13",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": 40.00
        },
        {
          "_id": 141,
          "_account_id": 50,
          "transaction_date": "2021-03-14",
          "transaction_payee": "Subway",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Lunch",
          "transaction_amount": 10.00
        },
        {
          "_id": 142,
          "_account_id": 50,
          "transaction_date": "2021-03-15",
          "transaction_payee": "Fast Food",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": 12.00
        },
        {
          "_id": 143,
          "_account_id": 50,
          "transaction_date": "2021-03-16",
          "transaction_payee": "Pharmacy",
          "transaction_category": "Health & Fitness",
          "transaction_memo": "Prescription",
          "transaction_amount": 30.00
        },
        {
          "_id": 144,
          "_account_id": 50,
          "transaction_date": "2021-03-17",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up tank",
          "transaction_amount": 40.00
        },],
      banks: [
        {
          "_id": "ABC123",
          "name": "Washington State Employees Credit Union",
          "acronym": "WSECU",
          "type": "Credit Union",
          "count": 10,
          "notification_text": "",
          "notification_showStatus": false,
          "address": "796 White Hawk LN SE",
          "phone": "3609706013",
          "city": "Tumwater",
          "state": "WA",
          "postcode": "98501",
          "routing": "125478"
        },
        {
          "_id": "123456",
          "name": "Bank of America",
          "acronym": "BOA",
          "routing": "235476951",
          "type": "Bank",
          "count": 10,
          "notification_text": "Address is incomplete",
          "notification_showStatus": true
        },
        {
          _id: '2',
          name: 'WellsFargo Bank',
          acronym: "WF",
          routing: "884hf98",
          type: 'Bank',
          count: 0,
          "notification_text": "Address is incomplete",
          "notification_showStatus": true
        },
        {
          _id: '3',
          name: 'Charter Bank',
          acronym: "CB",
          routing: "34854579845",
          type: 'Bank',
          count: 0,
          "notification_text": "Address is incomplete",
          "notification_showStatus": true
        },
        {
          _id: '4',
          name: 'KeyBank',
          acronym: "KB",
          routing: "83572457",
          type: 'Bank',
          count: 0,
          "notification_text": "Address is incomplete",
          "notification_showStatus": true
        },
      ],
      financial_categories: [
        {
          "category": "Income",
          "subcategories": [
            "Salary",
            "Investment Income",
            "Rental Income",
            "Side Income",
            "Social Security"
          ]
        },
        {
          "category": "Expenses",
          "subcategories": [
            "Housing",
            "Utilities",
            "Transportation",
            "Food",
            "Clothing",
            "Entertainment",
            "Education",
            "Childcare",
            "Medical/Healthcare",
            "Insurance",
            "Debt Payments",
            "Savings"
          ]
        },
        {
          "category": "Taxes",
          "subcategories": [
            "Federal Income Tax",
            "State Income Tax",
            "Property Tax",
            "Sales Tax"
          ]
        },
        {
          "category": "Investment",
          "subcategories": [
            "Stocks",
            "Bonds",
            "Mutual Funds",
            "Real Estate",
            "Retirement Accounts"
          ]
        },
        {
          "category": "Retirement Planning",
          "subcategories": [
            "401(k)",
            "IRA",
            "Roth IRA",
            "Social Security"
          ]
        },
        {
          "category": "Banking",
          "subcategories": [
            "Checking Accounts",
            "Savings Accounts",
            "Certificates of Deposit",
            "Money Market Accounts"
          ]
        },
        {
          "category": "Debt Management",
          "subcategories": [
            "Credit Card Debt",
            "Student Loan Debt",
            "Auto Loan",
            "Personal Loan"
          ]
        },
        {
          "category": "Insurance",
          "subcategories": [
            "Life Insurance",
            "Health Insurance",
            "Disability Insurance",
            "Homeowners/Renters Insurance",
            "Auto Insurance"
          ]
        },
        {
          "category": "Estate Planning",
          "subcategories": [
            "Will",
            "Trust",
            "Power of Attorney"
          ]
        }
      ],
      account_types_categories: [
        {
          type: "Savings Account",
          description: "An account designed to help you save money by earning interest on your deposits"
        },
        {
          type: "Checking Account",
          description: "A type of bank account that allows you to write checks or make electronic transfers to pay for goods or services"
        },
        {
          type: "Money Market Account",
          description: "A type of savings account that offers higher interest rates than traditional savings accounts but requires a minimum balance"
        },
        {
          type: "Certificate of Deposit (CD)",
          description: "A type of savings account that pays a fixed rate of interest for a specified period of time"
        },
        {
          type: "Individual Retirement Account (IRA)",
          description: "A retirement savings account that offers tax advantages to help you save for retirement"
        },
        {
          type: "Business Checking Account",
          description: "A type of bank account designed specifically for businesses to manage their finances"
        },
        {
          type: "Credit Card",
          description: "A type of loan that allows you to borrow money up to a certain limit and pay it back over time"
        },
        {
          type: "Debit Card",
          description: "A type of card that allows you to make purchases and withdraw cash from an account linked to your bank account"
        },
        {
          type: "Home Equity Line of Credit (HELOC)",
          description: "A type of loan that allows you to borrow against the equity in your home"
        },
        {
          type: "Online Banking Account",
          description: "A type of bank account that allows you to manage your finances online"
        },
        {
          type: "Investment Account",
          description: "A type of account that allows you to invest your money in stocks, bonds, mutual funds, and other investment products"
        },
        {
          type: "Prepaid Card",
          description: "A type of card that allows you to make purchases with funds that you have already loaded onto the card"
        },
        {
          type: "Trust Account",
          description: "A type of bank account that is set up to hold funds for a designated beneficiary"
        },
        {
          type: "Health Savings Account (HSA)",
          description: "A type of savings account that allows you to set aside money for medical expenses"
        },
        {
          type: "College Savings Account",
          description: "A type of savings account that allows you to save for a child’s college education"
        },
        {
          type: "Escrow Account",
          description: "A type of bank account that holds funds in trust until certain conditions are met"
        },
        {
          type: "Joint Account",
          description: "A type of bank account that is owned by two or more people"
        },
        {
          type: "Foreign Currency Account",
          description: "A type of bank account that allows you to hold and manage money in foreign currencies"
        },
        {
          type: "Interest Checking Account",
          description: "A type of checking account that pays interest on your deposits"
        },
        {
          type: "Business Savings Account",
          description: "A type of savings account designed specifically for businesses to manage their finances"
        },
        {
          type: "Line of Credit",
          description: "A type of loan that allows you to borrow money up to a certain limit and pay it back over time"
        },
        {
          type: "Brokerage Account",
          description: "A type of account that allows you to trade stocks, bonds, mutual funds, and other investments"
        },
        {
          type: "Roth IRA",
          description: "A type of retirement savings account that offers tax advantages to help you save for retirement"
        },
        {
          type: "401(k)",
          description: "A type of retirement savings plan that allows you to set aside money for retirement"
        },
        {
          type: "529 Plan",
          description: "A type of college savings plan that offers tax advantages to help you save for a child’s college education"
        },
        {
          type: "Savings Bond",
          description: "A type of government bond that allows you to save money for the future"
        },
        {
          type: "Travel Card",
          description: "A type of card that allows you to make purchases and withdraw cash while traveling abroad"
        },
        {
          type: "Gift Card",
          description: "A type of card that allows you to give someone a pre-loaded amount of money to use at a particular store or restaurant"
        },
        {
          type: "PayPal Account",
          description: "A type of online payment system that allows you to send and receive money"
        },
        {
          type: "Prepaid Phone Card",
          description: "A type of card that allows you to add money to your phone account and make calls"
        },
        {
          type: "Merchant Account",
          description: "A type of account that allows businesses to accept credit card payments from customers"
        },
        {
          type: "Business Credit Card",
          description: "A type of loan that allows businesses to borrow money up to a certain limit and pay it back over time"
        },
        {
          type: "Virtual Credit Card",
          description: "A type of credit card that is issued electronically and can be used to make purchases online"
        },
        {
          type: "Auto Loan",
          description: "A type of loan that allows you to borrow money to purchase a car"
        },
        {
          type: "Mortgage",
          description: "A type of loan that allows you to borrow money to purchase a home"
        },
        {
          type: "Student Loan",
          description: "A type of loan that allows you to borrow money to pay for college expenses"
        },
        {
          type: "Personal Loan",
          description: "A type of loan that allows you to borrow money for any personal purpose"
        },
        {
          type: "Home Equity Loan",
          description: "A type of loan that allows you to borrow against the equity in your home"
        },
        {
          type: "Custodial Account",
          description: "A type of bank account that is set up to hold funds for a minor"
        },
        {
          type: "Payroll Card",
          description: "A type of card that allows you to access your wages or salary without having to open a bank account"
        },
        {
          type: "Travelers Checks",
          description: "A type of check that can be used to make purchases while traveling abroad"
        },
        {
          type: "Prepaid Debit Card",
          description: "A type of card that allows you to make purchases with funds that you have already loaded onto the card"
        },
        {
          type: "Secured Credit Card",
          description: "A type of credit card that requires you to put down a security deposit in order to use it"
        },
        {
          type: "Reloadable Card",
          description: "A type of card that allows you to add money to it and use it to make purchases"
        },
        {
          type: "Rewards Credit Card",
          description: "A type of credit card that offers rewards or cash back for purchases made with it"
        },
        {
          type: "Business Debit Card",
          description: "A type of card that allows businesses to make purchases and withdraw cash from an account linked to their bank account"
        },
        {
          type: "Cryptocurrency Wallet",
          description: "A type of digital wallet that allows you to store, buy, and sell cryptocurrencies"
        },
        {
          type: "Apple Pay",
          description: "A type of digital wallet that allows you to make payments with your Apple device"
        },
        {
          type: "Google Pay",
          description: "A type of digital wallet that allows you to make payments with your Android device"
        },
        {
          type: "Venmo",
          description: "A type of digital wallet that allows you to send and receive money"
        },
        {
          type: "Payment Platforms (PayPal, Stripe, etc.)",
          description: "A type of online payment system that allows businesses to accept payments from customers"
        },
        {
          type: "Bill Pay Services (PayTrust, Quicken, etc.)",
          description: "A type of online service that allows you to pay bills electronically"
        }
      ],
      usStates: [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Federated States of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming"
      ],
    }
  },
  created() {
    this.localStorageTables = listTablesInLocalStorage().sort();
  },
  methods: {
    ...filters.filters,
    resetLocalStorage() {
      localstorageremove('banks');
      localstorageremove('accounts');
      localstorageremove('transactions');
      localstorageremove('account_types_categories')
      localstorageremove('usStates');
      this.loadtables()
    },
    saveUsStatesToLocalStorage() {
      localstorageset(this.usStates, 'usStates');
      this.loadtables()
    },
    saveBanksToLocalStorage() {
      localstorageset(this.banks, 'banks');
      this.loadtables()
    },
    saveAccountsToLocalStorage() {
      localstorageset(this.accounts, 'accounts');
      this.loadtables()
    },
    saveTransactionsToLocalStorage() {
      localstorageset(this.transactions, 'transactions');
      this.loadtables()
    },
    saveAccountTitlesToLocalStorage() {
      localstorageremove('account_types_categories')
      this.account_types_categories.sort((a, b) => (a.type > b.type) ? 1 : -1);
      localstorageset(this.account_types_categories.sort((a, b) => (a.type > b.type) ? 1 : -1), 'account_types_categories');
      this.loadtables();
    },
    loadtables() {
      this.localStorageTables = listTablesInLocalStorage().sort();
    }
  },
}
</script>

<style scoped>

</style>