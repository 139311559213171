<template>
  <div>
    <h1>HomeView not implemented</h1>
    <p>This is the future of BudgetSmarts dashboard.</p>
  </div>
</template>

<script>

  export default {
    name: 'HomeView',
  }
</script>
