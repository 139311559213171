/**
 * Remove a localStorage table by name
 * @param table
 */
function localstorageremove(table) {
  localStorage.removeItem(table);
}

function nuke(){
  localstorageremove('account');
  localstorageremove('anonymous_contributions')
  localstorageremove('anonymous_expenditures');
  localstorageremove('application');
  localstorageremove('bank');
  localstorageremove('interest');
  localstorageremove('cash_loans');
  localstorageremove('contacts');
  localstorageremove('corrections_contributions');
  localstorageremove('corrections_expenditures');
  localstorageremove('credit_card_debt');
  localstorageremove('data_schema');
  localstorageremove('logs');
  localstorageremove('monetary_contributions');
  localstorageremove('monetary_expenditures');
  localstorageremove('monthly_statement');
  localstorageremove('other_receipts');
  localstorageremove('statement');
  localstorageremove('subscriptions');
  localstorageremove('user');
  localstorageremove('year_to_date');
  localstorageremove('notifications');
}

module.exports = {localstorageremove, nuke};