const {localstorageget} = require("./localstorageget");
import Vue from 'vue'
import CryptoJS from "crypto-js";
import VueCryptojs from "vue-cryptojs";
Vue.use(VueCryptojs)


/**
 *
 * @param data
 * @returns {*}
 */
export function dataparse(pdata, table_name) {
  const data = (typeof pdata === 'string') ? JSON.parse(pdata) : pdata;
  console.log(data)
  if (typeof table_name === 'string') {
    // if a table is passed just send it to the database
    localstorageset(data, table_name, false)
  } else {
    // if no table is passed we need to parse out the entire json and insert into the tables
    if (data && typeof data === 'object') {
      this.setData(data);
    }
  }
  return data
}

/**
 * Returns userdata
 * @returns {{}}
 */
export function getData(){
  let data = {};
  data.account = localstorageget('account') ?? {};
  data.anonymous_contributions = localstorageget('anonymous_contributions') ?? [];
  data.anonymous_expenditures = localstorageget('anonymous_expenditures') ?? [];
  data.application = localstorageget('application') ?? {};
  data.bank = localstorageget('bank') ?? [];
  data.interest = localstorageget('interest') ?? [];
  data.cash_loans = localstorageget('cash_loans') ?? [];
  data.contacts = localstorageget('contacts') ?? [];
  data.corrections_contributions = localstorageget('corrections_contributions') ?? [];
  data.corrections_expenditures = localstorageget('corrections_expenditures') ?? [];
  data.credit_card_debt = localstorageget('credit_card_debt') ?? [];
  data.data_schema = localstorageget('data_schema') ?? [];
  data.logs = localstorageget('logs') ?? [];
  data.monetary_contributions = localstorageget('monetary_contributions') ?? [];
  data.monetary_expenditures = localstorageget('monetary_expenditures') ?? [];
  data.monthly_statement = localstorageget('monthly_statement') ?? [];
  data.other_receipts = localstorageget('other_receipts') ?? [];
  data.statement = localstorageget('statement') ?? [];
  data.user = localstorageget('user') ?? {};
  data.year_to_date = localstorageget('year_to_date') ?? [];
  data.notifications = localstorageget('notifications') ?? [];
  data.property_taxes = localstorageget('property_taxes') ?? [];
  return (data);
}

/**
 * Sets userdata
 * @param json_data
 * @returns {{}}
 */
export function setData(json_data){

  if (json_data && typeof json_data === 'object') {
    localstorageset(json_data.account                   ,'account', true);
    localstorageset(json_data.anonymous_contributions   ,'anonymous_contributions', true);
    localstorageset(json_data.anonymous_expenditures    ,'anonymous_expenditures', true);
    localstorageset(json_data.application               ,'application', true);
    localstorageset(json_data.bank                      ,'bank', true);
    localstorageset(json_data.interest                  ,'interest', true);
    localstorageset(json_data.cash_loans                ,'cash_loans', true);
    localstorageset(json_data.contacts                  ,'contacts', true);
    localstorageset(json_data.corrections_contributions ,'corrections_contributions', true);
    localstorageset(json_data.corrections_expenditures  ,'corrections_expenditures', true);
    localstorageset(json_data.credit_card_debt          ,'credit_card_debt', true);
    localstorageset(json_data.data_schema               ,'data_schema', true);
    localstorageset(json_data.logs                      ,'logs', true);
    localstorageset(json_data.monetary_contributions    ,'monetary_contributions', true);
    localstorageset(json_data.monetary_expenditures     ,'monetary_expenditures', true);
    localstorageset(json_data.monthly_statement         ,'monthly_statement', true);
    localstorageset(json_data.other_receipts            ,'other_receipts', true);
    localstorageset(json_data.statement                 ,'statement', true);
    localstorageset(json_data.user                      ,'user', true);
    localstorageset(json_data.year_to_date              ,'year_to_date', true);
    localstorageset(json_data.notifications             ,'notifications', true);
  }
  else {
    alert('Error: Wrong data type. ' + typeof json_data)
  }
}

function validateSubscription(data, token){
  let result = !(CryptoJS.AES.decrypt(data, token).toString(CryptoJS.enc.Utf8));
  return  result.replace('/','');
}

export function encrypt(data){

  // let result =  CryptoJS.AES.encrypt(JSON.stringify(data), token).toString();
  return encodeURIComponent(data);
  // return result
}
export function decrypt(data) {

  // let result = CryptoJS.AES.decrypt(JSON.parse(data), token).toString(CryptoJS.enc.Utf8);
  //
  //   return  result;
  return decodeURIComponent(data)
}

function localstorageremove(table) {
  localStorage.removeItem(table);
}
export function nuke(){
  localstorageremove('account');
  localstorageremove('anonymous_contributions')
  localstorageremove('anonymous_expenditures');
  localstorageremove('application');
  localstorageremove('bank');
  localstorageremove('interest');
  localstorageremove('cash_loans');
  localstorageremove('contacts');
  localstorageremove('corrections_contributions');
  localstorageremove('corrections_expenditures');
  localstorageremove('credit_card_debt');
  localstorageremove('data_schema');
  localstorageremove('logs');
  localstorageremove('monetary_contributions');
  localstorageremove('monetary_expenditures');
  localstorageremove('monthly_statement');
  localstorageremove('other_receipts');
  localstorageremove('statement');
  localstorageremove('subscriptions');
  localstorageremove('user');
  localstorageremove('year_to_date');
  localstorageremove('notifications');
}

export function doesTableExist(tablename){

  return (localStorage.getItem(tablename));
}

/**
 * Save data to localStorage
 * @param data
 * @param table
 * @param stringifyIt
 */
export function localstorageset(data, table, stringifyIt = true) {
  if (stringifyIt) {
    return localStorage.setItem(table, JSON.stringify(data))
  } else {
    return localStorage.setItem(table, data)
  }
}

// function parsebank(data){
//   localstorageset(data,'account',false )
// }





export default { dataparse, encrypt, decrypt, validateSubscription, getData, setData, nuke  }