<template>
  <div>
    <ButtonNewInstitution/>
    <TableBanks @openBankDialog="openBankInDialog(bank)" :banks="banks"/>




    <v-dialog
        v-model="showBankAccountsDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >

      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="showBankAccountsDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ bank.name }}
          </v-toolbar-title>
          <v-tooltip bottom>
            <template v-slot:activator="{attrs, on}">
              <v-btn icon v-bind="attrs" v-on="on" >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>View or edit {{bank.name}}'s contact information</span>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click="showBankAccountsDialog = false"
            >
              <v-icon>mdi-keyboard-backspace</v-icon>
              Back to Banks
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <accounts-view :bank="bank"/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showErrorDialog" max-width="600px">
      <v-card>
        <v-card-title class="darken-2 green white--text">Error</v-card-title>
        <v-card-text>
          {{ showError.message }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {localstorageget} from '@/utils/localstorageget';
import localstorageset from '@/utils/localstorageset';
import filters from "@/utils/filters";
import AccountsView from "@/views/AccountsView.vue";
import ButtonNewInstitution from "@/components/bank/ButtonNewInstitution.vue";
import TableBanks from "@/components/bank/TableBanks.vue";

/**
 * This component need to support more than one contact name, address, email, etc.
 */
export default {
  name: "BankView",
  components: {TableBanks, ButtonNewInstitution, AccountsView},
  data() {
    return {
      showBankAccountsDialog: false,
      emptyProperties: [],
      openMessageDialog: true,
      numberOfMessages: 5,
      deleteConfirmationDialog: false,
      banks: [
        {
          _id: 'ABC123',
          name: 'Washington State Employees Credit Union',
          acronym: 'WSECU'
        }, {
          _id: '123456',
          name: 'Bank of America',
          acronym: "BOA",
          routing: "235476951",
        },
        {
          _id: '2',
          name: 'WellsFargo Bank',
          acronym: "WF",
          routing: "884hf98",
        },
        {
          _id: '3',
          name: 'Charter Bank',
          acronym: "CB",
          routing: "34854579845",
        },
        {
          _id: '4',
          name: 'KeyBank',
          acronym: "KB",
          routing: "83572457",
        },
      ],
      bank: {},
      account: {},
      accounts: [],
      numberOfAccounts: 0,
      usStates: [],
      showErrorDialog: false,
      showError: {}
    }
  },
  created() {
    this.loadBanks();
    this.personalFinancialInstitutions.sort();
  },
  methods: {
    ...filters.filters,
    isObject(variable) {
      return typeof variable === "object" && !Array.isArray(variable) && variable !== null;
    },
    openBankInDialog(bank) {
      if (this.isObject(bank)) {
        this.showBankAccountsDialog = true;
        this.bank = bank;
      } else {
        console.error('invalid banking object', bank);
        this.bank = {};
        this.showErrorDialog = true;
        this.showError.message = "";
        this.showError.icon = "";
      }
    },
    tryDeleteBankById(bank) {
      this.bank = bank;
      this.deleteConfirmationDialog = true;
    },
    findBankRecord(bank) {
      this.newBankDialog = true;
      this.checkBankProperties(bank)
      this.bank = bank
    },
    deleteBankById() {
      let _id = this.bank._id;
      this.banks = this.banks.filter(item => item._id !== _id);
      localstorageset(this.banks, 'banks');
      this.loadBanks();
      this.deleteConfirmationDialog = false;
    },
    checkBankProperties(bank) {
      if (
          bank['address'] === null ||
          bank['address'] === undefined ||
          bank['address'] == '' ||
          bank['city'] === null ||
          bank['city'] === undefined ||
          bank['city'] == '' ||
          bank['state'] === null ||
          bank['state'] === undefined ||
          bank['state'] == '' ||
          bank['postcode'] === null ||
          bank['postcode'] === undefined ||
          bank['postcode'] == ''
      ) {
        this.bank.notification_text = "Address is incomplete";
        this.bank.notification_showStatus = true
      } else if (
          bank['phone'] === null ||
          bank['phone'] === undefined ||
          bank['phone'] == ''
      ) {
        this.bank.notification_text = "Phone is missing"
        this.bank.notification_showStatus = true
      } else if (

          bank['type'] === null ||
          bank['type'] === undefined ||
          bank['type'] == ''
      ) {

        this.bank.notification_showStatus = true
        this.bank.notification_text = "Missing the Type of Business"
      } else {
        this.bank.notification_text = "";
        this.bank.notification_showStatus = false;
      }


      // let bankKeys = []
      // this.emptyProperties = [];
      // for (let key in bankKeys) {
      //   console.log(bank[key])
      //   if (bank[key] === null || bank[key] === undefined || bank[key] === '') {
      //     this.emptyProperties.push(key);
      //     console.log()
      //   }
      // }
      // this.bank.numberOfMessages = this.emptyProperties.length;
      localstorageset(this.banks, 'banks')
    },
  },
  computed: {}
}
</script>

<style scoped>

</style>