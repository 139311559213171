import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"my-6"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({attrs, on}){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"primary","small":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" New transaction ")],1)]}}])},[_c('span',[_vm._v("New transaction for "+_vm._s(_vm.account.account_title))])])],1),_c(VSpacer),_c(VTextField,{attrs:{"dense":"","filled":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDataTable,{staticClass:"elevation-22",attrs:{"headers":_vm.headers,"items":_vm.transactions,"search":_vm.search,"item-key":"_id","sort-by":"payee"},scopedSlots:_vm._u([{key:"item.actions",fn:function({item}){return [_c(VBtn,{attrs:{"color":"primary","icon":"","text":""},on:{"click":function($event){return _vm.editItem(item._id)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VBtn,{attrs:{"color":"red","icon":"","text":""},on:{"click":function($event){return _vm.deleteItem(item._id)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)]}},{key:"item.transaction_amount",fn:function({item}){return [_c('span',[_vm._v(_vm._s(_vm.formatMoney(item.transaction_amount)))])]}},{key:"item.transaction_date",fn:function({item}){return [_c('span',[_vm._v(_vm._s(_vm.dateFormatShort(item.transaction_date)))])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }