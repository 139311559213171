<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{on, attrs}">
        <v-btn v-bind="attrs" v-on="on" class="mb-4" @click="tryCreateNewBank" color="primary" small>
          <v-icon class="mr-1">mdi-plus</v-icon>
          New institution
        </v-btn>
      </template>
      <span>Create a new institution</span>
    </v-tooltip>
    <v-dialog v-model="newBankDialog" max-width="650" persistent>
      <v-card>
        <v-card-title class="primary white--text">
          Edit/Add Bank
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>

          <v-autocomplete
              v-model="bank.type"
              :items="personalFinancialInstitutions"
              label="Type"
          ></v-autocomplete>

          <v-row dense>
            <v-col cols="12" md="6" lg="6">
              <v-text-field v-model="bank.name" label="Name"
                            hint="e.g. bank, college, payday loan business name"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-row>
                <v-col>
                  <v-text-field v-model="bank.acronym" label="Acronym" hint="Abbreviation or alias"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="bank.routing" label="ID"
                                hint="e.g. routing number, account number, unique identifier"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="6" lg="6">
              <v-text-field v-model="bank.address" label="Address" hint="Address"></v-text-field>

            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-text-field v-model="bank.phone" label="Phone" hint="Phone"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="3" lg="3">
              <v-text-field v-model="bank.city" label="City" hint="City"></v-text-field>
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <v-autocomplete
                  v-model="bank.state"
                  :items="usStates"
                  label="State"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <v-text-field v-model="bank.postcode" label="Postcode" hint="Postcode"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>

          <v-btn color="primary"
                 dark
                 @click="saveBank()"
          >
            Submit
          </v-btn>
          <v-btn @click="newBankDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import localstorageset from "@/utils/localstorageset";
import {localstorageget} from "@/utils/localstorageget";

export default {
  name: "ButtonNewInstitution",
  data(){
    return {
      usStates: [],
      bank: {},
      newBankDialog: false,
      personalFinancialInstitutions: [
        "Bank",
        "Credit Union",
        "Savings & Loan Association",
        "Investment Bank",
        "Brokerage Firm",
        "Mortgage Banker",
        "Insurance Company",
        "Online Bank",
        "Financial Advisor",
        "Stock Broker",
        "Money Market Account",
        "Money Manager",
        "Payday Loan Company",
        "Credit Card Company",
        "Debt Consolidation Firm",
        "Tax Preparation Firm",
        "Check Cashing Service",
        "Debt Relief Agency",
        "Financial Planner",
        "Personal Loan Lender",
        "Crowdfunding Platform",
        "Real Estate Investment Trust",
        "Peer-to-Peer Lender",
        "Prepaid Card Provider",
        "Cryptocurrency Exchange"],
    }
  },
created() {
  this.loadBanks();
  this.personalFinancialInstitutions.sort();
},
  methods: {
    loadBanks() {
      this.banks = localstorageget('banks') ?? [];
      this.usStates = localstorageget('usStates') ?? [];
    },
    saveBank() {
      if (this.bank._id && this.bank._id.length > 0) {
        // update
        console.log('updating')
        this.banks.forEach(item => {
          if (item._id === this.bank._id) {
            for (let key in this.bank) {
              item[key] = this.bank[key];
            }
          }
        });
        this.checkBankProperties(this.bank);
        localstorageset(this.banks, 'banks');
        this.bank = {};
        this.loadBanks();
      } else {
        console.log('saving')
        if (this.bank.name.length > 0 && this.bank.acronym.length > 0) {
          this.bank.registered = true;
          this.bank._id = this.generateId();

          this.checkBankProperties(this.bank);
          if (this.banks.length > 0) {
            this.banks.push(this.bank);
          } else {
            this.banks = [this.bank];
          }
          localstorageset(this.banks, 'banks');
          this.bank = {};
          this.loadBanks();
        }
      }
      this.newBankDialog = false;
    },
    tryCreateNewBank() {
      this.bank = {};
      this.newBankDialog = true;
    },
  }
}
</script>

<style scoped>

</style>