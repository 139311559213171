<template>
  <div>
    <div class="my-6">
      <v-tooltip bottom>
        <template v-slot:activator="{attrs, on}">
          <v-btn class="ma-1" color="primary" v-bind="attrs" v-on="on" small>
            <v-icon>mdi-plus</v-icon>
            New transaction
          </v-btn>
        </template>
        <span>New transaction for {{ account.account_title }}</span>
      </v-tooltip>
    </div>
    <v-spacer></v-spacer>
    <v-text-field
        dense
        filled
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
    ></v-text-field>
    <v-data-table
        :headers="headers"
        :items="transactions"
        :search="search"
        item-key="_id"
        sort-by="payee"
        class="elevation-22"
    >
      <template #item.actions="{item}">
        <v-btn color="primary" icon text @click="editItem(item._id)"><v-icon>mdi-pencil</v-icon></v-btn>
        <v-btn color="red" icon text @click="deleteItem(item._id)"><v-icon>mdi-delete</v-icon></v-btn>
      </template>
      <template #item.transaction_amount="{item}">
        <span>{{ formatMoney(item.transaction_amount) }}</span>
      </template>
      <template #item.transaction_date="{item}">
        <span>{{ dateFormatShort(item.transaction_date) }}</span>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import filters from "@/utils/filters";
export default {
  props:{
    account: {},
  },
  name: "AccountView",
  data() {
    return {
      search: '',
      account: {},
      bank: {},
      banks: [
        {
          _id: 'ABC123',
          name: 'Washington State Employees Credit Union',
          acronym: 'WSECU'
        }, {
          _id: '123456',
          name: 'Bank of America',
          acronym: "BOA",
          routing: "235476951",
        },
        {
          _id: '2',
          name: 'WellsFargo Bank',
          acronym: "WF",
          routing: "884hf98",
        },
        {
          _id: '3',
          name: 'Charter Bank',
          acronym: "CB",
          routing: "34854579845",
        },
        {
          _id: '4',
          name: 'KeyBank',
          acronym: "KB",
          routing: "83572457",
        },
      ],
      transactions: [
        {
          "_id": 100,
          "transaction_date": "2020-01-01",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 101,
          "transaction_date": "2020-01-02",
          "transaction_type": "credit",
          "transaction_payee": "Paycheck",
          "transaction_category": "Income",
          "transaction_memo": "January Paycheck",
          "transaction_amount": 2000,
          "_account_id": 50
        },
        {
          "_id": 102,
          "transaction_date": "2020-01-03",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 103,
          "transaction_date": "2020-01-04",
          "transaction_type": "debit",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": -30,
          "_account_id": 50
        },
        {
          "_id": 104,
          "transaction_date": "2020-01-05",
          "transaction_type": "debit",
          "transaction_payee": "Utilities",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "January Utilities",
          "transaction_amount": -150,
          "_account_id": 50
        },
        {
          "_id": 105,
          "transaction_date": "2020-01-06",
          "transaction_type": "credit",
          "transaction_payee": "Rent",
          "transaction_category": "Housing",
          "transaction_memo": "January Rent",
          "transaction_amount": 1500,
          "_account_id": 50
        },
        {
          "_id": 106,
          "transaction_date": "2020-01-07",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 107,
          "transaction_date": "2020-01-08",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 108,
          "transaction_date": "2020-01-09",
          "transaction_type": "debit",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": -30,
          "_account_id": 50
        },
        {
          "_id": 109,
          "transaction_date": "2020-01-10",
          "transaction_type": "credit",
          "transaction_payee": "Paycheck",
          "transaction_category": "Income",
          "transaction_memo": "January Paycheck",
          "transaction_amount": 2000,
          "_account_id": 50
        },
        {
          "_id": 110,
          "transaction_date": "2020-01-11",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 111,
          "transaction_date": "2020-01-12",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 112,
          "transaction_date": "2020-01-13",
          "transaction_type": "debit",
          "transaction_payee": "Utilities",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "January Utilities",
          "transaction_amount": -150,
          "_account_id": 50
        },
        {
          "_id": 113,
          "transaction_date": "2020-01-14",
          "transaction_type": "debit",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": -30,
          "_account_id": 50
        },
        {
          "_id": 114,
          "transaction_date": "2020-01-15",
          "transaction_type": "credit",
          "transaction_payee": "Rent",
          "transaction_category": "Housing",
          "transaction_memo": "January Rent",
          "transaction_amount": 1500,
          "_account_id": 50
        },
        {
          "_id": 115,
          "transaction_date": "2020-01-16",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 116,
          "transaction_date": "2020-01-17",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 117,
          "transaction_date": "2020-01-18",
          "transaction_type": "credit",
          "transaction_payee": "Paycheck",
          "transaction_category": "Income",
          "transaction_memo": "January Paycheck",
          "transaction_amount": 2000,
          "_account_id": 50
        },
        {
          "_id": 118,
          "transaction_date": "2020-01-19",
          "transaction_type": "debit",
          "transaction_payee": "Utilities",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "January Utilities",
          "transaction_amount": -150,
          "_account_id": 50
        },
        {
          "_id": 119,
          "transaction_date": "2020-01-20",
          "transaction_type": "debit",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": -30,
          "_account_id": 50
        },
        {
          "_id": 120,
          "transaction_date": "2020-01-21",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 121,
          "transaction_date": "2020-01-22",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 122,
          "transaction_date": "2020-01-23",
          "transaction_type": "credit",
          "transaction_payee": "Rent",
          "transaction_category": "Housing",
          "transaction_memo": "January Rent",
          "transaction_amount": 1500,
          "_account_id": 50
        },
        {
          "_id": 123,
          "transaction_date": "2020-01-24",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 124,
          "transaction_date": "2020-01-25",
          "transaction_type": "debit",
          "transaction_payee": "Utilities",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "January Utilities",
          "transaction_amount": -150,
          "_account_id": 50
        },
        {
          "_id": 125,
          "transaction_date": "2020-01-26",
          "transaction_type": "credit",
          "transaction_payee": "Paycheck",
          "transaction_category": "Income",
          "transaction_memo": "January Paycheck",
          "transaction_amount": 2000,
          "_account_id": 50
        },
        {
          "_id": 126,
          "transaction_date": "2020-01-27",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 127,
          "transaction_date": "2020-01-28",
          "transaction_type": "debit",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": -30,
          "_account_id": 50
        },
        {
          "_id": 128,
          "transaction_date": "2020-01-29",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 129,
          "transaction_date": "2020-01-30",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 130,
          "transaction_date": "2020-01-31",
          "transaction_type": "credit",
          "transaction_payee": "Rent",
          "transaction_category": "Housing",
          "transaction_memo": "January Rent",
          "transaction_amount": 1500,
          "_account_id": 50
        },
        {
          "_id": 131,
          "transaction_date": "2020-02-01",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 132,
          "transaction_date": "2020-02-02",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 133,
          "transaction_date": "2020-02-03",
          "transaction_type": "debit",
          "transaction_payee": "Utilities",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "January Utilities",
          "transaction_amount": -150,
          "_account_id": 50
        },
        {
          "_id": 134,
          "transaction_date": "2020-02-04",
          "transaction_type": "debit",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": -30,
          "_account_id": 50
        },
        {
          "_id": 135,
          "transaction_date": "2020-02-05",
          "transaction_type": "credit",
          "transaction_payee": "Paycheck",
          "transaction_category": "Income",
          "transaction_memo": "January Paycheck",
          "transaction_amount": 2000,
          "_account_id": 50
        },
        {
          "_id": 136,
          "transaction_date": "2020-02-06",
          "transaction_type": "debit",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Groceries",
          "transaction_memo": "Weekly Groceries",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 137,
          "transaction_date": "2020-02-07",
          "transaction_type": "debit",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up",
          "transaction_amount": -50,
          "_account_id": 50
        },
        {
          "_id": 138,
          "transaction_date": "2020-02-08",
          "transaction_type": "debit",
          "transaction_payee": "Utilities",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "January Utilities",
          "transaction_amount": -150,
          "_account_id": 50
        },
        {
          "_id": 139,
          "transaction_date": "2020-02-09",
          "transaction_type": "debit",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": -30,
          "_account_id": 50
        },
        {
          "_id": 100,
          "_account_id": 50,
          "transaction_date": "2021-02-01",
          "transaction_payee": "McDonalds",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Lunch",
          "transaction_amount": 15.00
        },
        {
          "_id": 101,
          "_account_id": 50,
          "transaction_date": "2021-02-02",
          "transaction_payee": "Walmart",
          "transaction_category": "Shopping",
          "transaction_memo": "Groceries",
          "transaction_amount": 75.00
        },
        {
          "_id": 102,
          "_account_id": 50,
          "transaction_date": "2021-02-03",
          "transaction_payee": "Target",
          "transaction_category": "Shopping",
          "transaction_memo": "Household supplies",
          "transaction_amount": 50.00
        },
        {
          "_id": 103,
          "_account_id": 50,
          "transaction_date": "2021-02-04",
          "transaction_payee": "Apple Store",
          "transaction_category": "Shopping",
          "transaction_memo": "New phone",
          "transaction_amount": 800.00
        },
        {
          "_id": 104,
          "_account_id": 50,
          "transaction_date": "2021-02-05",
          "transaction_payee": "Starbucks",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Coffee",
          "transaction_amount": 5.00
        },
        {
          "_id": 105,
          "_account_id": 50,
          "transaction_date": "2021-02-06",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up tank",
          "transaction_amount": 40.00
        },
        {
          "_id": 106,
          "_account_id": 50,
          "transaction_date": "2021-02-07",
          "transaction_payee": "Utility Company",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "Electricity bill",
          "transaction_amount": 120.00
        },
        {
          "_id": 107,
          "_account_id": 50,
          "transaction_date": "2021-02-08",
          "transaction_payee": "Amazon",
          "transaction_category": "Shopping",
          "transaction_memo": "Books",
          "transaction_amount": 25.00
        },
        {
          "_id": 108,
          "_account_id": 50,
          "transaction_date": "2021-02-09",
          "transaction_payee": "Bank",
          "transaction_category": "Transfer",
          "transaction_memo": "Transfer to savings",
          "transaction_amount": 100.00
        },
        {
          "_id": 109,
          "_account_id": 50,
          "transaction_date": "2021-02-10",
          "transaction_payee": "Pharmacy",
          "transaction_category": "Health & Fitness",
          "transaction_memo": "Prescription",
          "transaction_amount": 30.00
        },
        {
          "_id": 110,
          "_account_id": 50,
          "transaction_date": "2021-02-11",
          "transaction_payee": "Home Depot",
          "transaction_category": "Shopping",
          "transaction_memo": "Tools",
          "transaction_amount": 45.00
        },
        {
          "_id": 111,
          "_account_id": 50,
          "transaction_date": "2021-02-12",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Weekly groceries",
          "transaction_amount": 75.00
        },
        {
          "_id": 112,
          "_account_id": 50,
          "transaction_date": "2021-02-13",
          "transaction_payee": "Movie Theater",
          "transaction_category": "Entertainment",
          "transaction_memo": "Movie night",
          "transaction_amount": 25.00
        },
        {
          "_id": 113,
          "_account_id": 50,
          "transaction_date": "2021-02-14",
          "transaction_payee": "Subway",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Lunch",
          "transaction_amount": 10.00
        },
        {
          "_id": 114,
          "_account_id": 50,
          "transaction_date": "2021-02-15",
          "transaction_payee": "Clothing Store",
          "transaction_category": "Shopping",
          "transaction_memo": "New shirt",
          "transaction_amount": 25.00
        },
        {
          "_id": 115,
          "_account_id": 50,
          "transaction_date": "2021-02-16",
          "transaction_payee": "Insurance Company",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "Car insurance",
          "transaction_amount": 150.00
        },
        {
          "_id": 116,
          "_account_id": 50,
          "transaction_date": "2021-02-17",
          "transaction_payee": "Home Improvement Store",
          "transaction_category": "Shopping",
          "transaction_memo": "Paint",
          "transaction_amount": 50.00
        },
        {
          "_id": 117,
          "_account_id": 50,
          "transaction_date": "2021-02-18",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": 40.00
        },
        {
          "_id": 118,
          "_account_id": 50,
          "transaction_date": "2021-02-19",
          "transaction_payee": "Department Store",
          "transaction_category": "Shopping",
          "transaction_memo": "New shoes",
          "transaction_amount": 75.00
        },
        {
          "_id": 119,
          "_account_id": 50,
          "transaction_date": "2021-02-20",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up tank",
          "transaction_amount": 40.00
        },
        {
          "_id": 120,
          "_account_id": 50,
          "transaction_date": "2021-02-21",
          "transaction_payee": "Gym",
          "transaction_category": "Health & Fitness",
          "transaction_memo": "Monthly membership",
          "transaction_amount": 50.00
        },
        {
          "_id": 121,
          "_account_id": 50,
          "transaction_date": "2021-02-22",
          "transaction_payee": "Taxi",
          "transaction_category": "Transportation",
          "transaction_memo": "Taxi ride",
          "transaction_amount": 20.00
        },
        {
          "_id": 122,
          "_account_id": 50,
          "transaction_date": "2021-02-23",
          "transaction_payee": "Hardware Store",
          "transaction_category": "Shopping",
          "transaction_memo": "Tools",
          "transaction_amount": 25.00
        },
        {
          "_id": 123,
          "_account_id": 50,
          "transaction_date": "2021-02-24",
          "transaction_payee": "Netflix",
          "transaction_category": "Entertainment",
          "transaction_memo": "Monthly subscription",
          "transaction_amount": 10.00
        },
        {
          "_id": 124,
          "_account_id": 50,
          "transaction_date": "2021-02-25",
          "transaction_payee": "Starbucks",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Coffee",
          "transaction_amount": 5.00
        },
        {
          "_id": 125,
          "_account_id": 50,
          "transaction_date": "2021-02-26",
          "transaction_payee": "Fast Food",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": 12.00
        },
        {
          "_id": 126,
          "_account_id": 50,
          "transaction_date": "2021-02-27",
          "transaction_payee": "Furniture Store",
          "transaction_category": "Shopping",
          "transaction_memo": "New couch",
          "transaction_amount": 500.00
        },
        {
          "_id": 127,
          "_account_id": 50,
          "transaction_date": "2021-02-28",
          "transaction_payee": "Grocery Store",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Weekly groceries",
          "transaction_amount": 75.00
        },
        {
          "_id": 128,
          "_account_id": 50,
          "transaction_date": "2021-03-01",
          "transaction_payee": "Insurance Company",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "Health insurance",
          "transaction_amount": 200.00
        },
        {
          "_id": 129,
          "_account_id": 50,
          "transaction_date": "2021-03-02",
          "transaction_payee": "McDonalds",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Lunch",
          "transaction_amount": 15.00
        },
        {
          "_id": 130,
          "_account_id": 50,
          "transaction_date": "2021-03-03",
          "transaction_payee": "Apple Store",
          "transaction_category": "Shopping",
          "transaction_memo": "New laptop",
          "transaction_amount": 1200.00
        },
        {
          "_id": 131,
          "_account_id": 50,
          "transaction_date": "2021-03-04",
          "transaction_payee": "Hair Salon",
          "transaction_category": "Health & Fitness",
          "transaction_memo": "Haircut",
          "transaction_amount": 25.00
        },
        {
          "_id": 132,
          "_account_id": 50,
          "transaction_date": "2021-03-05",
          "transaction_payee": "Walmart",
          "transaction_category": "Shopping",
          "transaction_memo": "Groceries",
          "transaction_amount": 75.00
        },
        {
          "_id": 133,
          "_account_id": 50,
          "transaction_date": "2021-03-06",
          "transaction_payee": "Taxi",
          "transaction_category": "Transportation",
          "transaction_memo": "Taxi ride",
          "transaction_amount": 20.00
        },
        {
          "_id": 134,
          "_account_id": 50,
          "transaction_date": "2021-03-07",
          "transaction_payee": "Home Depot",
          "transaction_category": "Shopping",
          "transaction_memo": "Tools",
          "transaction_amount": 45.00
        },
        {
          "_id": 135,
          "_account_id": 50,
          "transaction_date": "2021-03-08",
          "transaction_payee": "Utility Company",
          "transaction_category": "Bills & Utilities",
          "transaction_memo": "Gas bill",
          "transaction_amount": 50.00
        },
        {
          "_id": 136,
          "_account_id": 50,
          "transaction_date": "2021-03-09",
          "transaction_payee": "Amazon",
          "transaction_category": "Shopping",
          "transaction_memo": "Books",
          "transaction_amount": 25.00
        },
        {
          "_id": 137,
          "_account_id": 50,
          "transaction_date": "2021-03-10",
          "transaction_payee": "Bank",
          "transaction_category": "Transfer",
          "transaction_memo": "Transfer to savings",
          "transaction_amount": 100.00
        },
        {
          "_id": 138,
          "_account_id": 50,
          "transaction_date": "2021-03-11",
          "transaction_payee": "Movie Theater",
          "transaction_category": "Entertainment",
          "transaction_memo": "Movie night",
          "transaction_amount": 25.00
        },
        {
          "_id": 139,
          "_account_id": 50,
          "transaction_date": "2021-03-12",
          "transaction_payee": "Clothing Store",
          "transaction_category": "Shopping",
          "transaction_memo": "New shirt",
          "transaction_amount": 25.00
        },
        {
          "_id": 140,
          "_account_id": 50,
          "transaction_date": "2021-03-13",
          "transaction_payee": "Restaurant",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": 40.00
        },
        {
          "_id": 141,
          "_account_id": 50,
          "transaction_date": "2021-03-14",
          "transaction_payee": "Subway",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Lunch",
          "transaction_amount": 10.00
        },
        {
          "_id": 142,
          "_account_id": 50,
          "transaction_date": "2021-03-15",
          "transaction_payee": "Fast Food",
          "transaction_category": "Food & Dining",
          "transaction_memo": "Dinner",
          "transaction_amount": 12.00
        },
        {
          "_id": 143,
          "_account_id": 50,
          "transaction_date": "2021-03-16",
          "transaction_payee": "Pharmacy",
          "transaction_category": "Health & Fitness",
          "transaction_memo": "Prescription",
          "transaction_amount": 30.00
        },
        {
          "_id": 144,
          "_account_id": 50,
          "transaction_date": "2021-03-17",
          "transaction_payee": "Gas Station",
          "transaction_category": "Transportation",
          "transaction_memo": "Fill up tank",
          "transaction_amount": 40.00
        },],
      headers: [
        {text: "Date", value: 'transaction_date'},
        {text: "Payee", value: 'transaction_payee'},
        {text: "Category", value: 'transaction_category'},
        {text: "Amount", value: 'transaction_amount', align: "right"},
        {text: "Actions", value: 'actions'}
      ],
      accounts: [
        {
          "_id": 50,
          "account_title": "Savings Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Savings Account",
          "description": "Savings account for John Doe",
          "account_number": "123456789",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active"
        },
        {
          "_id": 51,
          "account_title": "Checking Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Checking Account",
          "description": "Checking account for John Doe",
          "account_number": "987654321",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active"
        },
        {
          "_id": 52,
          "account_title": "Investment Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Investment Account",
          "description": "Investment account for John Doe",
          "account_number": "123456789",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active"
        },
        {
          "_id": 53,
          "account_title": "Retirement Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Retirement Account",
          "description": "Retirement account for John Doe",
          "account_number": "987654321",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active"
        },
        {
          "_id": 54,
          "account_title": "Credit Card Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Credit Card Account",
          "description": "Credit card account for John Doe",
          "account_number": "123456789",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active"
        },
        {
          "_id": 55,
          "account_title": "Debit Card Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Debit Card Account",
          "description": "Debit card account for John Doe",
          "account_number": "987654321",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active"
        },
        {
          "_id": 56,
          "account_title": "Loan Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Loan Account",
          "description": "Loan account for John Doe",
          "account_number": "123456789",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active"
        },
        {
          "_id": 57,
          "account_title": "Mortgage Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Mortgage Account",
          "description": "Mortgage account for John Doe",
          "account_number": "987654321",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active"
        },
        {
          "_id": 58,
          "account_title": "Line of Credit Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Line of Credit Account",
          "description": "Line of credit account for John Doe",
          "account_number": "123456789",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active"
        },
        {
          "_id": 59,
          "account_title": "Savings Bond Account",
          "_bank_id": "ABC123",
          "account_name": "John Doe Savings Bond Account",
          "description": "Savings bond account for John Doe",
          "account_number": "987654321",
          "accounting_year": "2020",
          "creation_date": "01/01/2020",
          "account_status": "active"
        },
        {
          "_id": "1",
          "account_title": "Savings Account",
          "accountName": "My Savings Account",
          "description": "This is my savings account for emergency funds.",
          "account_number": "123456",
          "accounting_year": "2017",
          "creation_date": "01-01-2017",
          "account_status": "Not Active"
        },
        {
          "_id": "2",
          "account_title": "Checking Account",
          "accountName": "My Checking Account",
          "description": "This is my checking account for everyday expenses.",
          "account_number": "789012",
          "accounting_year": "2012",
          "creation_date": "01-01-2012",
          "account_status": "Not Active"
        },
        {
          "_id": "3",
          "account_title": "Retirement Account",
          "accountName": "My Retirement Account",
          "description": "This is my retirement account for long-term savings.",
          "account_number": "345678",
          "accounting_year": "2020",
          "creation_date": "01-01-2020",
          "account_status": "Active"
        },
        {
          "_id": "4",
          "account_title": "Investment Account",
          "accountName": "My Investment Account",
          "description": "This is my investment account for stocks and bonds.",
          "account_number": "901234",
          "accounting_year": "2022",
          "creation_date": "01-01-2022",
          "account_status": "Active"
        },
        {
          "_id": "5",
          "account_title": "Credit Card Account",
          "accountName": "My Credit Card Account",
          "description": "This is my credit card account for short-term expenses.",
          "account_number": "567890",
          "accounting_year": "2020",
          "creation_date": "01-01-2020",
          "account_status": "Active"
        },
        {
          "_id": "6",
          "account_title": "Loan Account",
          "accountName": "My Loan Account",
          "description": "This is my loan account for large purchases.",
          "account_number": "234567",
          "accounting_year": "2020",
          "creation_date": "01-01-2020",
          "account_status": "Active"
        },
        {
          "_id": "7",
          "account_title": "CD Account",
          "accountName": "My CD Account",
          "description": "This is my CD account for short-term savings.",
          "account_number": "890123",
          "accounting_year": "2020",
          "creation_date": "01-01-2020",
          "account_status": "Active"
        },
        {
          "_id": "8",
          "account_title": "Savings Bond Account",
          "accountName": "My Savings Bond Account",
          "description": "This is my savings bond account for long-term savings.",
          "account_number": "678901",
          "accounting_year": "2020",
          "creation_date": "01-01-2020",
          "account_status": "Active"
        },
        {
          "_id": "9",
          "account_title": "Money Market Account",
          "accountName": "My Money Market Account",
          "description": "This is my money market account for higher interest rates.",
          "account_number": "345678",
          "accounting_year": "2020",
          "creation_date": "01-01-2020",
          "account_status": "Active"
        },
        {
          "_id": "10",
          "account_title": "Gift Card Account",
          "accountName": "My Gift Card Account",
          "description": "This is my gift card account for gifts and rewards.",
          "account_number": "901234",
          "accounting_year": "2020",
          "creation_date": "01-01-2020",
          "account_status": "Active"
        }, {
          "_id": 10,
          "account_title": "Savings Account",
          "_bank_id": "123456",
          "accountName": "My Savings",
          "description": "This is my personal savings account",
          "account_number": "123456789",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active"
        },
        {
          "_id": 11,
          "account_title": "Checking Account",
          "_bank_id": "123456",
          "accountName": "My Checking",
          "description": "This is my personal checking account",
          "account_number": "987654321",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active"
        },
        {
          "_id": 12,
          "account_title": "Retirement Account",
          "_bank_id": "123456",
          "accountName": "My Retirement",
          "description": "This is my personal retirement account",
          "account_number": "567891234",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active"
        },
        {
          "_id": 13,
          "account_title": "Investment Account",
          "_bank_id": "123456",
          "accountName": "My Investments",
          "description": "This is my personal investment account",
          "account_number": "123498765",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active"
        },
        {
          "_id": 14,
          "account_title": "Credit Card Account",
          "_bank_id": "123456",
          "accountName": "My Credit Card",
          "description": "This is my personal credit card account",
          "account_number": "456789123",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active"
        },
        {
          "_id": 15,
          "account_title": "Loan Account",
          "_bank_id": "123456",
          "accountName": "My Loan",
          "description": "This is my personal loan account",
          "account_number": "789123456",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active"
        },
        {
          "_id": 16,
          "account_title": "Saving Bond Account",
          "_bank_id": "123456",
          "accountName": "My Saving Bond",
          "description": "This is my personal saving bond account",
          "account_number": "345678912",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active"
        },
        {
          "_id": 17,
          "account_title": "Money Market Account",
          "_bank_id": "123456",
          "accountName": "My Money Market",
          "description": "This is my personal money market account",
          "account_number": "891234567",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active"
        },
        {
          "_id": 18,
          "account_title": "Certificate of Deposit Account",
          "_bank_id": "123456",
          "accountName": "My CD",
          "description": "This is my personal certificate of deposit account",
          "account_number": "678912345",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active"
        },
        {
          "_id": 19,
          "account_title": "Health Savings Account",
          "_bank_id": "123456",
          "accountName": "My HSA",
          "description": "This is my personal health savings account",
          "account_number": "912345678",
          "accounting_year": "2020",
          "creation_date": "1/1/2020",
          "account_status": "active"
        }
      ],
      accounts_trans: [],
    }
  },
  methods: {
    ...filters.filters,
    editItem(id) {
      alert('Edit id'+ id)
    },
    deleteItem(id) {
      alert('delete id'+ id)
    },
    loadData(){
      const accountObj = this.accounts.filter(e => e._bank_id === this.account._bank_id);
      this.account = accountObj[0];
    },
  },
  created() {

    const accountObj = this.accounts.filter(e => e._bank_id === this.account._bank_id);
    this.account = accountObj[0];
    //this.accounts_trans = this.transactions.filter(e => e._bank_id === this.$route.params._id);


  },
  watch:{
    account_id(){
      this.loadData();
    }
  }
}
</script>

<style scoped>

</style>