import Vue from 'vue';
import moment from 'moment'
import {v4 as uuidv4} from 'uuid';

// function formatMoney(amount, sign, type) {
//   let decimalCount = 2, decimal = ".", thousands = ",";
//   try {
//     decimalCount = Math.abs(decimalCount);
//     decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
//
//
//     const negativeSign = type === 'debit' ? "-" : "";
//
//     let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
//     let j = (i.length > 3) ? i.length % 3 : 0;
//
//     return sign ? '$' + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "") :
//       negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
//   } catch (e) {
//     console.log(e)
//   }
// }
//
//
// function formatMoney2(amount, sign) {
//   let decimalCount = 2, decimal = ".", thousands = ",";
//   try {
//     decimalCount = Math.abs(decimalCount);
//     decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
//
//
//     const negativeSign = amount < 0 ? '-' : "";
//
//
//     let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
//     let j = (i.length > 3) ? i.length % 3 : 0;
//
//     return sign ? '$' + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "") :
//       negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
//   } catch (e) {
//     console.log(e)
//   }
// }

const {format} = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2
});


export default {
  filters: {
    distinctAccountingYears(arr) {
      let accountingYears = [];
      arr.forEach(obj => {
        if (!accountingYears.includes(obj.accounting_year)) {
          accountingYears.push(obj.accounting_year);
        }
      });
      return accountingYears.sort();
    },
    formatPhoneNumber(numbers) {
      let formattedNumber = "(";
      for (let i = 0; i < numbers.length; i++) {
        if (i === 3) {
          formattedNumber += ") ";
        } else if (i === 6) {
          formattedNumber += "-";
        }
        formattedNumber += numbers[i];
      }
      return formattedNumber;
    },
    formatMoney(amount, defaultValue = '$0.00') {
      let formattedMoney = '';
      try {
        if (typeof amount !== "undefined" && amount && !isNaN(amount)) {
          formattedMoney = this.addParenthesis(format(amount));
        } else {
          formattedMoney = defaultValue;
        }
      } catch (e) {
        console.log(e)
      }
      return formattedMoney;
    },
    addParenthesis(val) {
      const temp = val.replace('$', '');
      if (temp < 0) {
        return "$(" + temp.replace('-', '') + ")";
      } else {
        return val;
      }
    },
    dateObj() {
      return new Date();
    },
    theDay() {
      // let day = new Array('st ', 'nd ', 'rd ', 'th ');
      let result = 'th';
      switch (this.dateObj().getDay()) {
        case 1:
          result = 'st';
          break;
        case 2:
          result = 'nd';
          break;
        case 3:
          result = 'rd';
          break;
        case 4:
          result = 'th';
          break;
      }
      return this.dateObj().getDay() + result;
    },
    theMonth() {
      let month = new Array(12);
      month[0] = "January";
      month[1] = "February";
      month[2] = "March";
      month[3] = "April";
      month[4] = "May";
      month[5] = "June";
      month[6] = "July";
      month[7] = "August";
      month[8] = "September";
      month[9] = "October";
      month[10] = "November";
      month[11] = "December";
      return month[this.dateObj().getMonth()];
    },
    theYear() {
      return this.dateObj().getFullYear();
    },
    generateId() {
      return uuidv4();
    },
    // helper for generateProductKey for key generation
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    // generates budgetsmart license
    monthlyRecurrence(data, start) {
      let transaction = [];
      const d = data.contr_date.split('-')
      const yr = d[0];
      const dy = d[2];
      const begin = parseInt(start);

      // eslint-disable-next-line no-unused-vars
      let daa = {}
      let trans_date = '';
      if (data) {
        for (let i = begin; 12 >= i; i++) {

          if (i <= 9) {
            // do the 1 - 9
            trans_date = yr + '-' + '0' + i + '-' + dy
          } else {
            // do 10 - 12
            trans_date = yr + '-' + i + '-' + dy
          }
          daa = {
            "consolidated": false,
            "contr_id": this.generatePersonId(),
            "contr_date": trans_date,
            "contr_name": data.contr_name,
            "contr_category": data.contr_category,
            "contr_amount": data.contr_amount,
            "contr_note": data.contr_note,
            "type": data.type
          };

          if (transaction.length > 0) {
            transaction.push(daa);
          } else {
            // new
            transaction = [daa];
          }
          // console.log('loop ' + i + ': ' + JSON.stringify(transaction))
        }
      }
      return transaction;
    },
    generatePersonId() {
      let tokens = "ChakraEnergyT23498ranmutedInTime",
        chars = 4,
        segments = 4,
        keyString = "";
      for (let i = 0; i < segments; i++) {
        let segment = "";
        for (let j = 0; j < chars; j++) {
          let k = this.getRandomInt(0, 35);
          segment += tokens[k];
        }
        keyString += segment;
        if (i < (segments - 1)) {
          keyString += "a";
        }
      }
      return keyString.replace('undefine', '');

    },
    generateProductKey() {
      let tokens = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        chars = 5,
        segments = 4,
        keyString = "";
      for (let i = 0; i < segments; i++) {
        let segment = "";
        for (let j = 0; j < chars; j++) {
          let k = this.getRandomInt(0, 35);
          segment += tokens[k];
        }
        keyString += segment;
        if (i < (segments - 1)) {
          keyString += "-";
        }
      }
      return keyString;
    },
    trimAllSpaces: value => {
      return value.split(" ").join("");
    },
    // Yes or NO from boolean
    yesNo: value => value ? 'Yes' : 'No',

    dateFormatAsYear: value => {
      if (value) {
        return moment(value).format('YYYY-M-D');
      }
      return '';
    },
    dateFormatShort: value => {
      if (value) {
        return moment(value).format('M/D/YYYY');
      }
      return '';
    },

    dateFormat: value => {
      if (value) {
        return moment(value).format('MMMM D, YYYY');
      }
      return '';
    },

    dateTimeFormat: value => {
      if (value) {
        return moment(value).format('M/D/YYY HH:mm');
      }
      return '';
    },

    defaultText: (value, text) => {
      if (!value) {
        return text;
      }
      return value;
    },

    upperCase: value => {
      if (value) {
        return value.toUpperCase();
      }
      return value;
    },

    lowerCase: value => {
      if (value) {
        return value.toLowerCase();
      }
      return value;
    },

    // Title Case a field or code.
    titleCase: value => {
      if (value) {
        let str = value.toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
          str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ');
      }
      return '';
    },

    // Create a (value) but only if the value exists.
    parenthetical: value => {
      if (value) {
        return '(' + value + ')';
      }
      return '';
    },

    // Display a label only if true.
    ifTrue: (value, label) => {
      if (value) {
        return label
      }
      return '';
    },

    // Display value only if equals comparator.
    ifEquals: (value, comparator) => {
      if (value === comparator) {
        return value;
      }
      return '';
    },

    // Display value only if not equal to comparator.
    ifNotEquals: (value, comparator) => {
      if (value !== comparator) {
        return value;
      }
      return '';
    },

    // Suffix the value with a label if it is present.
    suffix: (value, label) => {
      if (value) {
        return value + label;
      }
      return '';
    },


    // Prefix the value with a label if it is present.
    prefix: (value, label) => {
      if (value) {
        return label + value;
      }
      return '';
    },


    // Return the proper values for ministerial and treasurer in the proper formatting
    treasurerFormat: (treasurer, ministerial, title) => {
      let compareTitle = title ? title.toLowerCase() : '';
      if (compareTitle !== "treasurer" && treasurer && ministerial) {
        return ' (ministerial treasurer)';
      } else if (compareTitle === "treasurer" && treasurer && ministerial) {
        return ' (ministerial)';
      } else if (compareTitle !== "treasurer" && treasurer && !ministerial) {
        return ' (treasurer)';
      } else {
        return '';
      }
    },
    paintMoney(value) {
      if (typeof value === 'number') {
        if (value >= 0) {
          return 'green';
        } else if (value < 0) {
          return 'red';
        }
      } else {
        if (value === 'credit') {
          return 'green';
        } else if (value === 'debit') {
          return 'red'
        }
      }

    },
    color_money(value) {
      return value < 0 ? 'red' : 'green';
    },
    sum(a, b) {
      return parseFloat((parseFloat(a) + parseFloat(b)).toFixed(2));
    },
    generateFileName() {
      const date = new Date();
      const year = date.getFullYear();
      const month = `${date.getMonth() + 1}`.padStart(2, '0');
      const day = `${date.getDate()}`.padStart(2, '0');
      const hour = `${date.getHours()}`
      const minute = `${date.getMinutes()}`
      const second = `${date.getSeconds()}`
      return `${year}${month}${day}${hour}${minute}${second}` + '.budgetsmart';
    },
  },
  defineGlobalFilters() {

    for (let f in this.filters) {
      Vue.filter(f, this.filters[f]);
    }

  }
}