import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#047a44',
        secondary: '#000000',
        third: '#1976d2',
        anchor: '#1976d2',
        fourth: '#f5f5d9',
      }
    }
  }
});
