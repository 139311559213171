
function localstorageget(tablename, turnonparser = true) {
  if (turnonparser) {
    if (localStorage.getItem(tablename)) {
      let t = localStorage.getItem(tablename);
      return JSON.parse(t);
    }
  } else {
    if (localStorage.getItem(tablename)) {
      return localStorage.getItem(tablename);
    }
  }
}


function listTablesInLocalStorage() {
  let tables = [];
  for (let i = 0; i < localStorage.length; i++) {
    tables.push(localStorage.key(i));
  }
  return tables;
}

function db_exists(tablename){
  return (localStorage.getItem(tablename))
}

module.exports = {localstorageget, db_exists, listTablesInLocalStorage};
